<template>
  <div class="wrap" v-loading="loading">
    <div class="tips" v-if="isPrompt">{{ prompt }}</div>
    <el-form ref="form" :model="takeOutForm" :rules="rules" label-width="80px">
      <topOperatingButton
        :disabled="disabled"
        :showAudit="showAudit"
        :isAddBtn="showAdd"
        :isExamineBtn="true"
        @submitForm="submitForm"
        @addBill="addBill"
        @auditBill="auditBill"
        @handleAdd="handleAdd"
        @getQuit="getQuit"
        id="topOperatingButton"
      >
      </topOperatingButton>
      <!-- 顶部按钮 -->
      <!-- <div class="btnTOP">
        <el-button
          type="primary"
          size="mini"
          :disabled="disabled"
          @click="submitForm(true)"
          >保存
        </el-button>
        <el-button
          class="marR10"
          type="primary"
          size="mini"
          :disabled="disabled"
          @click="addBill"
          >保存并新增
        </el-button>
        <el-dropdown
          split-button
          @click="auditBill('审核')"
          icon="el-icon-circle-check"
          :disabled="showAudit"
          @command="auditBill"
        >
          审核
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="反审核">反审核</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          class="marL10"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          v-show="showAdd"
          @click="handleAdd"
          >新增</el-button
        >
        <el-button @click="$router.push('/marketing/coupon/takeOut')"
          >退出</el-button
        >
      </div> -->
      <!-- 基本信息 -->
      <cardTitleCom
        cardTitle="基本信息"
        :auditOrder="auditOrder"
        :noAuditOrder="noAuditOrder"
        :stopOrder="stopOrder"
        :deleteOrder="deleteOrder"
        :disableOrder="disableOrder"
      >
        <template slot="cardContent">
          <div class="tableContent">
            <div class="x-x marB10">
              <el-form-item label="方案编号" class="label" prop="billNo">
                <el-input
                  class="inputWidth marR15"
                  :disabled="true"
                  v-model="takeOutForm.billNo"
                  size="mini"
                  placeholder="方案编号"
                ></el-input>
              </el-form-item>
              <el-form-item label="方案名称" prop="promoName">
                <el-input
                  class="inputWidth"
                  :disabled="disabled"
                  v-model="takeOutForm.promoName"
                  size="mini"
                  placeholder="方案名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="有效日期" prop="datetime">
                <el-date-picker
                  size="mini"
                  v-model="takeOutForm.datetime"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :disabled="disabled"
                  :picker-options="forbiddenTime"
                >
                </el-date-picker>
              </el-form-item>
              <!-- <el-form-item label="单据日期" prop="billDate">
                <el-date-picker
                  style="width: 170px"
                  v-model="takeOutForm.billDate"
                  type="date"
                  placeholder="单据日期"
                  :disabled="disabled"
                  size="mini"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item> -->
              <el-form-item label="备注" prop="billRemark">
                <el-input
                  class="inputRemark"
                  :disabled="disabled"
                  v-model="takeOutForm.billRemark"
                  size="mini"
                  type="textarea"
                  placeholder="备注长度介于 1 和 80 字符之间"
                  maxlength="80"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                />
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>

      <!-- 礼包内容 -->
      <cardTitleCom cardTitle="选择优惠券类型">
        <template slot="cardContent">
          <div class="tableContent">
            <!-- 赠送优惠券 -->
            <div class="couponTable marT10">
              <el-table
                ref="couponTable"
                :data="takeOutForm.detailItem"
                border
                row-key="couponCaseId"
                @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellMouseLeave"
              >
                <el-table-column
                  align="center"
                  :label="tableCellLabel"
                  width="120"
                  :v-if="!disabled"
                >
                  <template v-slot="scope">
                    <i
                      @click="tableRow('push', scope.$index)"
                      class="el-icon-circle-plus operatePush"
                      v-if="scope.row.hoverRow && !disabled"
                    ></i>
                    <i
                      @click="tableRow('del', scope.$index)"
                      class="el-icon-remove operateDel"
                      v-if="scope.row.hoverRow && !disabled"
                    ></i>
                    <div v-if="!scope.row.hoverRow || disabled">
                      {{ scope.$index + 1 }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="优惠券方案编号"
                  width="220"
                  align="center"
                  prop="couponCaseNo"
                  height="300"
                  key="couponCaseNo"
                >
                  <template slot="header">
                    <i style="color: #ff4949">*</i>
                    优惠券方案编号
                  </template>
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'detailItem.' + scope.$index + '.couponCaseNo'"
                      :rules="rules[`detailItem.couponCaseNo`]"
                    >
                      <el-input
                        @keyup.enter.native="couponKeyupEnter($event, scope.$index)"
                        size="mini"
                        v-model="scope.row.couponCaseNo"
                        :disabled="disabled"
                      >
                        <i
                          slot="suffix"
                          class="el-icon-more more"
                          @click="
                            handleEvent('openCouponDialog', { curTable: 'detailItem' })
                          "
                          :disabled="disabled"
                        ></i>
                        <!-- <i
                          slot="suffix"
                          class="el-icon-more more"
                          @click="openCoupon = true"
                          :disabled="disabled"
                          ></i> -->
                      </el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="优惠券名称"
                  align="center"
                  prop="couponCaseName"
                  show-overflow-tooltip
                  key="couponCaseName"
                />
                <el-table-column
                  label="优惠券类型"
                  align="center"
                  prop="couponCaseTypeName"
                  key="couponCaseTypeName"
                />
                <el-table-column
                  label="优惠券形式"
                  align="center"
                  prop="couponModeName"
                  key="couponModeName"
                />

                <el-table-column
                  label="优惠券内容"
                  align="center"
                  prop="couponDesc"
                  :show-overflow-tooltip="true"
                  key="couponDesc"
                />

                <el-table-column
                  label="数量"
                  align="center"
                  prop="limitQty"
                  key="limitQty"
                  width="180"
                >
                  <template slot="header">
                    <i style="color: #ff4949">*</i>
                    数量 (每个会员)
                  </template>
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'detailItem.' + scope.$index + '.limitQty'"
                      :rules="rules[`detailItem.limitQty`]"
                    >
                      <el-input
                        v-model="scope.row.limitQty"
                        :disabled="disabled"
                        size="mini"
                        @input="handleInput(scope.$index)"
                      >
                      </el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  label="有效期时间"
                  align="center"
                  prop="expirationDate"
                  width="320"
                  key="expirationDate"
                /> -->
              </el-table>
            </div>
          </div>
        </template>
      </cardTitleCom>

      <!-- 适用对象规则设置 -->
      <cardTitleCom cardTitle="适用对象规则设置">
        <template slot="cardContent">
          <div class="tableContent padd10">
            <!-- 适用会员 -->
            <div>
              <div class="x-x">
                <el-form-item label="适用对象" class="label" prop="vipLevelRangeTypeArr">
                  <el-checkbox-group
                    v-model="takeOutForm.vipLevelRangeTypeArr"
                    @change="handleCheckVip"
                    :disabled="disabled"
                  >
                    <el-checkbox label="0">全部会员</el-checkbox>
                    <el-checkbox label="1">全部会员+排除</el-checkbox>
                    <el-checkbox label="2">指定会员</el-checkbox>
                    <el-checkbox label="4">生日会员</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-select
                  v-model="takeOutForm.vipBirthdayType"
                  class="selectVipBD marL10 inputWidth"
                  v-show="showVipBD"
                  clearable
                  size="mini"
                  filterable
                  :disabled="disabled"
                >
                  <el-option
                    :label="dict.label"
                    v-for="dict in dict.type.vip_birthday_type"
                    :key="dict.value"
                    :value="dict.value"
                  >
                  </el-option>
                </el-select>
              </div>

              <!-- 非全部会员 -->
              <div class="marL20" v-show="vipTable">
                <!-- <el-button class="marB10" @click="getVipOpenDig"
                  >选择会员copy</el-button
                > -->
                <el-button
                  class="marB10"
                  @click="handleEvent('openVipDialog', { curTable: 'vipLevelItem' })"
                  >选择会员</el-button
                >
                <!-- 
                <el-button class="marB10" @click="getVipOpenDigRank"
                  >选择会员等级copy</el-button
                > -->
                <el-button
                  class="marB10"
                  @click="handleEvent('openVipLevelDialog', { curTable: 'vipLevelItem' })"
                  >选择会员等级</el-button
                >
                <!-- 会员表格 -->
                <!-- takeOutForm.vipLevelItem -->
                <!-- 可编辑表格 -->
                <EditTable :options="vipOptions" />
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 选择优惠券对话框 -->
    <selectCoupon
      :OpenCoupon.sync="openCoupon"
      @getCoupons="getPitchData($event, 'coupon')"
    ></selectCoupon>
    <!-- 选择会员信息框 -->
    <selectVips :OpenVipCards.sync="openVipDia" @getVipS="getVipList" />
    <!-- 会员等级 -->
    <vipLeve :OpenVipLeve.sync="openVipLeve" @vipLeveList="getVipLeveList" />

    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import { uniqWith } from "lodash"; //去重
import Dialog from "@/components/Dialog";
import EditTable from "@/components/editTable"; //可编辑表格
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import comTable from "@/views/components/com-table"; //表格
import selectCoupon from "@/views/components/selectCoupon"; //优惠券组件
import selectVips from "@/views/components/selectVips/index.vue"; //选择会员
import vipLeve from "@/views/components/vipLeve"; //会员等级
import { getNewDate } from "@/utils/newDate"; //单据日期
import { getBillNo } from "@/api/codeRule"; //单据号
import { CouponList } from "@/api/marketing/coupon/case"; //搜索优惠券接口api
import {
  takeOutAddAPI,
  takeOutDetailAPI,
  takeOutUpdateAPI,
  getUpdateStatusState,
  takeOutUpdateStatusAPI,
} from "@/api/marketing/coupon/takeOut"; //优惠券发放
export default {
  name: "takeOutDetail",
  dicts: [
    "vip_birthday_type", //会员生日范围
  ],
  components: {
    Dialog,
    EditTable,
    topOperatingButton,
    cardTitleCom,
    comTable,
    selectCoupon,
    selectVips,
    vipLeve,
  },
  data() {
    return {
      isPrompt: false,
      prompt: "优惠券发放中",
      timer: null,
      tableCellLabel: "序号",
      //优惠券表格配置
      vipOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: 300, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        isNoDelBtn: false,
        hideTotal: true,
        columns: [
          {
            prop: "lineTypeName",
            label: "类型",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "lineNo",
            label: "编号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "lineName",
            label: "名称",
            minWidth: 100,
            align: "center",
          },
        ],
      },
      curTable: "",
      //弹窗配置
      dialogOptions: {
        title: "选择会员",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      //会员等级弹窗开关
      openVipLeve: false,
      //会员弹窗开关
      openVipDia: false,
      //选择优惠券开关
      openCoupon: false,
      //会员表格字段
      vipTableLable: [
        { label: "编号", prop: "lineNo" },
        { label: "名称", prop: "lineName" },
      ],
      //会员表格开关
      vipTable: false,
      //开启会员生日下拉框开关
      showVipBD: false,
      //标识已审核
      auditOrder: false,
      //标识未审核
      noAuditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识已删除
      deleteOrder: false,
      //标识已禁用
      disableOrder: false,
      showAudit: true, //审核按钮禁用状态
      showAdd: false, //新增按钮
      disabled: false, //保存禁用
      openGoods: false, //选择商品信息开关
      queryCode: undefined, //传送商品参数
      loadingTable: false, //表格遮罩
      loading: false, //大盒子遮罩层
      //旧的表单
      oldForm: {},
      // 表单参数
      takeOutForm: {
        datetime: "", //有效日期
        billDate: "", //单据日期
        billStatus: "", //单据审核状态
        //券类型
        detailItem: [{}],
        //适用对象
        vipLevelRangeTypeArr: ["0"],
        //适用对象表格
        vipLevelItem: [],
      },
      // 表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: "请到编码规则配置单据类型",
            trigger: ["blur", "change"],
          },
        ],
        promoName: [
          {
            required: true,
            message: "方案名称不能为空",
            trigger: ["blur", "change"],
          },
        ],
        billDate: [
          {
            required: true,
            message: "单据日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        vipLevelRangeTypeArr: [
          {
            required: true,
            message: "适用对象不能为空",
            trigger: ["blur", "change"],
          },
        ],
        datetime: [
          {
            required: true,
            message: "请选择有效日期",
            trigger: ["blur", "change"],
          },
        ],
        "detailItem.couponCaseNo": [
          {
            required: true,
            message: "优惠券方案编号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "detailItem.limitQty": [
          {
            required: true,
            message: "优惠券限量不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      //促销日期 禁用当天之前的时间
      forbiddenTime: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
    };
  },
  watch: {
    //侦听发放对象 当全部会员+排除/指定会员时 显示会员表格 反之隐藏
    "takeOutForm.vipLevelRangeTypeArr": {
      handler(val) {
        if (val) {
          //当选中全部会员+排除/指定会员时 显示会员表格 反之隐藏
          const bool = val.some((v) => v === "1" || v === "2");
          this.vipTable = bool ? true : false;
          //当选中生日会员  显示会员生日下拉框 反之隐藏
          const vipBD = val.some((v) => v === "4");
          this.showVipBD = vipBD ? true : false;
        }
      },
      immediate: true,
    },
    //侦听单据状态
    "takeOutForm.billStatus": {
      handler(newVal) {
        if (["2", "3", "4", 2, 3, 4].includes(this.takeOutForm.billStatus)) {
          this.vipOptions.isNoDelBtn = true;
        }
        if (this.takeOutForm.billStatus == "2") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = true;
          //打开审核图标
          this.auditOrder = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = false;
          //关闭已禁用图标
          this.disableOrder = false;
          //关闭关闭图标
          this.stopOrder = false;
          //关闭已删除图标
          this.deleteOrder = false;
          //关闭未审核图标
          this.noAuditOrder = false;
        } else if (this.takeOutForm.billStatus == "3") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;
          //打开已删除图标
          this.deleteOrder = true;
          //关闭已禁用图标
          this.disableOrder = false;
          //关闭关闭图标
          this.stopOrder = false;
          //关闭审核图标
          this.auditOrder = false;
          //关闭未审核图标
          this.noAuditOrder = false;
          //禁用审核按钮
          this.showAudit = true;
          //禁止全部输入按钮
          this.disabled = true;
        } else if (this.takeOutForm.billStatus == "0") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;
          //未审核图标
          this.noAuditOrder = true;
          //禁用审核按钮
          this.showAudit = false;
          //关闭审核图标
          this.auditOrder = false;
          //关闭已禁用图标
          this.disableOrder = false;
          //关闭关闭图标
          this.stopOrder = false;
          //关闭已删除图标
          this.deleteOrder = false;
          //恢复全部输入
          this.disabled = false;
        } else if (this.takeOutForm.billStatus == "4") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;
          //打开关闭图标
          this.stopOrder = true;
          //未审核图标
          this.noAuditOrder = false;
          //禁用审核按钮
          this.showAudit = false;
          //关闭审核图标
          this.auditOrder = false;
          //关闭已禁用图标
          this.disableOrder = false;
          //关闭已删除图标
          this.deleteOrder = false;
          //恢复全部输入
          this.disabled = true;
        } else if (this.takeOutForm.billStatus == "") {
          //引入源单按钮
          this.showImportBill = true;
          //新增按钮
          this.showAdd = false;
          //关闭已禁用图标
          this.disableOrder = false;
          //关闭关闭图标
          this.stopOrder = false;
          //关闭已删除图标
          this.deleteOrder = false;
          //关闭审核图标
          this.auditOrder = false;
          //关闭未审核图标
          this.noAuditOrder = false;
          //禁用审核按钮
          this.showAudit = true;
          //恢复全部输入
          this.disabled = false;
        }
      },
      immediate: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log("xxxxxx");
    next(async (vm) => {
      vm.loading = true;
      // 重置数据
      vm.reset();
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === "Update") {
        if (vm.$route.query.billId) {
          try {
            //获取单据详情
            const { data } = await takeOutDetailAPI(vm.$route.query.billId);
            //单据赋值
            vm.takeOutForm = data;
            vm.takeOutForm.datetime = [vm.takeOutForm.begTime, vm.takeOutForm.endTime];
            vm.vipOptions.list = vm.takeOutForm.vipLevelItem;
            //原始单据赋值
            vm.takeOutForm.vipBirthdayType = vm.takeOutForm.vipBirthdayType
              ? vm.takeOutForm.vipBirthdayType.toString()
              : "";
            vm.oldForm = JSON.parse(JSON.stringify(vm.takeOutForm));
            // if (vm.takeOutForm.billStatus != 2) {
            //   vm.loadingFun(vm.takeOutForm.billId);
            // }
            vm.loading = false;
          } catch (err) {
            vm.loading = false;
          }
        }
      } else {
        //初始化数据
        await vm.getInitializeData();
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != "{}") {
          vm.takeOutForm = vm.oldForm;
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.takeOutForm));
      }
      vm.loading = false;
    });
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.takeOutForm.billId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.takeOutForm));
    }
    next();
  },
  // mounted () {
  //   this.loadingFun()
  // },
  methods: {
    loadingFun(billId) {
      this.isPrompt = true;
      getUpdateStatusState(billId)
        .then((res) => {
          console.log(res, "res");
          if (res.msg == "优惠券发放中，请耐心等待...") {
            setTimeout(() => {
              this.loadingFun(billId);
            }, 5000);
          } else {
            // this.$message.success(res.msg)
            this.$alert("发放优惠券成功", "提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                // this.$message({ //   type: 'info',//   message: `action: ${ action }` // });
              },
            });
            this.isPrompt = false;
          }
        })
        .catch((err) => {
          console.log(err, "err");
          this.isPrompt = false;
        });
    },
    cellMouseEnter(row) {
      let eleIndex = this.takeOutForm.detailItem.indexOf(row);
      this.takeOutForm.detailItem[eleIndex].hoverRow = true;
      this.tableCellLabel = "操作";
    },
    cellMouseLeave() {
      this.takeOutForm.detailItem = this.takeOutForm.detailItem.map((item) => ({
        ...item,
        hoverRow: false,
      }));
      this.tableCellLabel = "序号";
    },
    handleInput(index) {
      const regex = /^(?:100|[1-9]\d?)$/;
      let value = this.takeOutForm.detailItem[index].limitQty;
      if (!regex.test(value)) {
        this.takeOutForm.detailItem[index].limitQty = "";
      }
    },
    getQuit() {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          (item) => item.path === this.$route.path
        ),
        1
      );
      history.back();
    },
    async handleEvent(type, row) {
      if (row?.curTable) this.curTable = row.curTable; //标识当前表格
      switch (type) {
        case "openCouponDialog":
          this.dialogOptions = {
            curType: "couponCaseId",
            title: "选择优惠券",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            // formData: this.$dialog({ key: 'coupon', option:{ queryParams: { isStops: 0, isInvalid: 1}}})
            formData: this.$dialog({
              key: "coupon",
              option: { queryParams: { isStops: 0, isInvalids: [0, 1] } },
            }),
          };
          break;
        case "openVipDialog":
          this.dialogOptions = {
            curType: "vipId",
            title: "选择会员",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "vip" }),
          };
          break;
        case "openVipLevelDialog":
          this.dialogOptions = {
            curType: "vipLevelId",
            title: "选择会员级别",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "vipLevel" }),
          };
          break;
        case "dialogChange":
          let list = [];
          let dialogType = this.dialogOptions.curType;
          if (dialogType != "timeDate") {
            if (this.dialogOptions.formData.table.check.length <= 0) return;
            list = this.dialogOptions.formData.table.check;
            if (["vipId", "vipLevelId"].includes(dialogType)) {
              list = this.getTableData(dialogType, list);
              list = [...list, ...this.vipOptions.list];
              list = uniqWith(list, (x, y) => x.lineId == y.lineId);
              this.vipOptions.list = list;
            } else {
              list = [...list, ...this.takeOutForm[this.curTable]];
              list = list.filter((item) => item.couponCaseId != undefined);
              list = uniqWith(list, (x, y) => x[dialogType] == y[dialogType]);
              this.takeOutForm[this.curTable] = list;
            }
          }
          break;
        default:
          break;
      }
    },
    getTableData(type, list) {
      let newList = [];
      switch (type) {
        case "vipId":
          newList = list.flatMap((item) => ({
            ...item,
            lineType: 1,
            lineTypeName: "会员",
            lineId: item.vipId,
            lineName: item.vipName,
            lineNo: item.vipNo,
          }));
          break;
        case "vipLevelId":
          newList = list.flatMap((item) => ({
            ...item,
            lineType: 2,
            lineTypeName: "会员等级",
            lineId: item.vipLevelId,
            lineName: item.vipLevelName,
            lineNo: item.vipLevelNo,
          }));
          break;
        case "couponCaseId":
          newList = list.flatMap((item) => ({
            ...item,
            hoverRow: false,
          }));
          break;
        default:
          break;
      }
      return newList;
    },
    //新增按钮
    async handleAdd() {
      this.loading = true;
      this.reset();
      await this.getInitializeData();
      this.loading = false;
    },
    /** 提交按钮 */
    async submitForm(isBool,isAddBill) {
      this.takeOutForm.vipLevelItem = this.vipOptions.list;
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.takeOutForm.begTime = this.takeOutForm.datetime[0];
          this.takeOutForm.endTime = this.takeOutForm.datetime[1];
          if (this.takeOutForm.billId) {
            const res = await takeOutUpdateAPI(this.takeOutForm);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.takeOutForm.billId = res.data?.billId;
              this.takeOutForm.billStatus = res.data?.billStatus;
              // this.takeOutForm.vipBirthdayType = this.takeOutForm.vipBirthdayType
              //   ? this.takeOutForm.vipBirthdayType.toString()
              //   : "";

              this.$modal.msgSuccess("修改单据成功");
            } else {
              this.$modal.msgSuccess("保存并新增单据成功");
            }
          } else {
            const { data } = await takeOutAddAPI(this.takeOutForm);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.takeOutForm.billId = data?.billId;
              this.takeOutForm.billStatus = data?.billStatus;
              // this.takeOutForm.vipBirthdayType = this.takeOutForm.vipBirthdayType
              //   ? this.takeOutForm.vipBirthdayType.toString()
              //   : "";
              this.$modal.msgSuccess("新增单据成功");
            } else {
              this.$modal.msgSuccess("保存并修改单据成功");
            }
          }
          //保存并提交时，待数据提交以后再清空页面，否则提交异常
          if(isAddBill){
            this.reset();
            await this.getInitializeData();
          }
        }
      });
    },
    //删除表格内容
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    //优惠券表格回车事件 event.target.value 拿到输入的值   index 优惠券表格数组的下标
    couponKeyupEnter(event, index) {
      //判断是否输入值
      if (event.target.value) {
        CouponList({
          query: event.target.value,
        }).then(async (response) => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            this.$set(this.takeOutForm.detailItem, index, response.rows[0]);
          } else {
            //反之直接弹出商品信息框
            this.openCoupon = true;
          }
        });
      } else {
        //没输入直接弹出商品信息框
        event.target.blur();
        this.openCoupon = true;
      }
    },
    //优惠券
    getPitchData(value) {
      this.takeOutForm.detailItem = value;
    },
    //导入会员等级
    getVipLeveList(value) {
      if (value && value.length <= 0) {
        return;
      }
      //去重
      value = value.filter((item) => {
        return this.takeOutForm.vipLevelItem.every((v) => v.lineId != item.vipLevelId);
      });
      if (value && value.length <= 0) {
        return;
      }
      //遍历行的类型
      value.forEach((item) => {
        this.$set(item, "lineType", 2);
        this.$set(item, "lineTypeName", "会员等级");
        this.$set(item, "lineId", item.vipLevelId);
        this.$set(item, "lineName", item.vipLevelName);
        this.$set(item, "lineNo", item.vipLevelNo);
      });
      this.takeOutForm.vipLevelItem.push(...value);
      this.takeOutForm.vipLevelItem = this.takeOutForm.vipLevelItem.filter(
        (item) => item.lineType != undefined
      );
    },
    //导入会员
    getVipList(value) {
      if (value && value.length <= 0) {
        return;
      }

      //去重
      value = value.filter((item) => {
        return this.takeOutForm.vipLevelItem.every((v) => v.lineId != item.vipId);
      });

      if (value && value.length <= 0) {
        return;
      }

      //遍历行的类型
      value.forEach((item) => {
        this.$set(item, "lineType", 1);
        this.$set(item, "lineTypeName", "会员");
        this.$set(item, "lineId", item.vipId);
        this.$set(item, "lineNo", item.vipNo);
        this.$set(item, "lineName", item.vipName);
      });
      this.takeOutForm.vipLevelItem.push(...value);
      this.takeOutForm.vipLevelItem = this.takeOutForm.vipLevelItem.filter(
        (item) => item.lineType != undefined
      );
    },
    //打开选择会员级别对话框
    getVipOpenDigRank() {
      this.openVipLeve = true;
    },
    //打开选择会员对话框
    getVipOpenDig() {
      this.openVipDia = true;
    },
    // 表单重置
    reset() {
      //新增按钮
      this.showAdd = false;
      //关闭已删除图标
      this.deleteOrder = false;
      //关闭审核图标
      this.auditOrder = false;
      //关闭未审核图标
      this.noAuditOrder = false;
      //关闭关闭图标
      this.stopOrder = false;
      //关闭已禁用图标
      this.disableOrder = false;
      //保存禁用
      this.disabled = false;
      this.takeOutForm = {
        datetime: "", // 有效日期
        billDate: "", //单据日期
        billStatus: "", //单据审核状态
        //券类型
        detailItem: [{}],
        //适用对象
        vipLevelRangeTypeArr: ["0"],
        //适用对象表格
        vipLevelItem: [],
      };
      this.vipOptions.list = [];
      this.resetForm("form");
    },
    //初始化页面数据
    async getInitializeData() {
      this.loading = true;
      //获取单据日期
      this.takeOutForm.billDate = getNewDate();
      //获取单据编号
      this.takeOutForm.billNo = await getBillNo(160202);
      this.loading = false;
    },
    //表格增加/减少一行方法  list数组 type类型(增加/减少) index下标
    tableRow(type, index) {
      if (type === "push") {
        this.takeOutForm.detailItem.splice(index + 1, 0, {});
      } else {
        if (this.takeOutForm.detailItem.length <= 1) {
          this.$set(this.takeOutForm.detailItem, index, {});
          return;
        }
        this.takeOutForm.detailItem.splice(index, 1);
      }
    },
    //选中发放对象改变触发(选中全部会员去除指定会员,选中指定会员去除全部会员)
    handleCheckVip() {
      if (
        this.takeOutForm.vipLevelRangeTypeArr &&
        this.takeOutForm.vipLevelRangeTypeArr.length > 0
      ) {
        // 拿到最后一个选择的数据  要以最后一个选择的为判断
        if (
          this.takeOutForm.vipLevelRangeTypeArr[
            this.takeOutForm.vipLevelRangeTypeArr.length - 1
          ] === "0"
        ) {
          //当最后一个数据是全部会员 去除指定会员/全部会员+排除/会员生日
          this.takeOutForm.vipLevelRangeTypeArr = this.takeOutForm.vipLevelRangeTypeArr.filter(
            (v) => v !== "2" && v !== "1"
          );
        } else if (
          this.takeOutForm.vipLevelRangeTypeArr[
            this.takeOutForm.vipLevelRangeTypeArr.length - 1
          ] === "1"
        ) {
          //当最后一个数据是全部会员+排除 去除指定会员/全部会员/会员生日
          this.takeOutForm.vipLevelRangeTypeArr = this.takeOutForm.vipLevelRangeTypeArr.filter(
            (v) => v !== "2" && v !== "0"
          );
        } else if (
          this.takeOutForm.vipLevelRangeTypeArr[
            this.takeOutForm.vipLevelRangeTypeArr.length - 1
          ] === "2"
        ) {
          //当最后一个数据是指定会员 去除全部会员/全部会员+排除
          this.takeOutForm.vipLevelRangeTypeArr = this.takeOutForm.vipLevelRangeTypeArr.filter(
            (v) => v !== "0" && v !== "1"
          );
        }
      }
    },
    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });

        if (name === "审核") {
          if (this.takeOutForm.billStatus != "0") {
            this.$message.error("此单据不是未审核的状态哦~");
            return;
          }
          const obj = {
            billIds: [this.takeOutForm.billId],
            billStatus: "2",
          };
          //发送审核api
          const { data } = await takeOutUpdateStatusAPI(obj);
          this.takeOutForm = data;
          this.takeOutForm.datetime = [this.takeOutForm.begTime, this.takeOutForm.endTime];
          //原始单据赋值
          this.takeOutForm.vipBirthdayType = this.takeOutForm.vipBirthdayType
              ? this.takeOutForm.vipBirthdayType.toString()
              : "";
          // 弹出提示
          this.$message.success("审核成功");
        } else if (name === "反审核") {
          if (this.takeOutForm.billStatus != "2") {
            this.$message.error("此单据不是已审核的状态哦~");
            return;
          }
          const obj = {
            billIds: [this.takeOutForm.billId],
            billStatus: "0",
          };
          //发送反审核api
          const { data } = await takeOutUpdateStatusAPI(obj);
          this.takeOutForm = data;
          this.takeOutForm.datetime = [this.takeOutForm.begTime, this.takeOutForm.endTime];
          this.takeOutForm.vipBirthdayType = this.takeOutForm.vipBirthdayType
              ? this.takeOutForm.vipBirthdayType.toString()
              : "";
          // 弹出提示
          this.$message.success("反审核成功");
        }
        this.loadingFun(this.takeOutForm.billId);
      } catch {}
    },
    //保存并新增单据
    async addBill() {
      this.$refs["form"].validate(async (valid) =>{
        if (valid) {
          this.$confirm("是否保存并新增单据?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "success",
          })
            .then(async () => {
              this.loading = true;
              await this.submitForm(false,true);
              // this.reset();
              // await this.getInitializeData();

              this.loading = false;
            })
            .catch();
          }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.isPrompt {
}
::v-deep .create-isLoading {
  z-index: 1999;
  top: 85px;
  ::v-deep .el-loading-mask {
    top: 85px !important;
  }
}
::v-deep .el-radio,
.el-checkbox {
  color: #333;
}
.label {
  ::v-deep .el-form-item__label {
    text-align: left;
  }
}
.wrap {
  background-color: #eaeaea;
  padding: 40px 10px 6px 10px;
  min-height: 100vh;
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  .tableContent {
    margin: 15px 10px 15px 10px;
    //优惠券表格
    .couponTable {
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
    }
  }
  //内部表单
  ::v-deep .el-form-item {
    padding: 0;
    margin: 0;
  }
  //单据日期
  ::v-deep .el-date-editor--datetimerange.el-input,
  .el-date-editor--datetimerange.el-input__inner {
    width: 355px !important;
  }
  /* 复选框选中后的字体颜色 */
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #333333 !important;
  }
}
.tips {
  position: fixed;
  width: 300px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-left: -150px;
  margin-top: -100px;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  line-height: 200px;
  z-index: 300;
  color: #fff;
  border-radius: 5px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 180px !important;
}
</style>
