<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import Dialog from '@/components/Dialog'; 
import { cloneDeep } from "lodash";
import {
  getPaperMarketingCouponBookList
} from '@/api/marketing/coupon/case'

import { recordListAPI, cancelMarketingCouponCaseBook, uploadFileCoupon, queryGetImportState } from '@/api/marketing/coupon/drawAndVerifyRecord' //优惠券账本记录
export default {
  name: "specialprice",
  components: { TablePage, Dialog },
  data() {
    return {
      //弹窗配置
      dialogOptions: {
        curType: '', //当前类型(门店/商品/类别/品牌)
        title: '文件导入',
        width: 600,
        show: false,
        type: 'ImportFile'
      },
      options: {
        mutiSelect: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        exportOption: {
          fastExportUrl:
            '/api/system/marketing/base/coupon/case/book/listExport',
          exportName: '优惠券明细记录'
        },
        getListApi: recordListAPI,
        body: {},
        listNo: true,
        title: "单据信息",
        check: [],
        rowKey: "couponId",
        search: [
          {
            label: "领用时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ drawTimeBegin: a, drawTimeEnd: b }),
            // seniorSearch: true
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "方案编号/方案名称/优惠券号/会员卡号/会员名称/手机号",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "couponCaseNos", label: "方案编号" },
              { filter: "couponCaseNames", label: "方案名称" },
              { filter: "couponNos", label: "优惠券号" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "手机号" }
            ],
          },
          {
            label: "生效时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ validBegTimeBegin: a, validBegTimeEnd: b }),
            seniorSearch: true
          },
          {
            label: "失效时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ validEndTimeBegin: a, validEndTimeEnd: b }),
            seniorSearch: true
          },
          this.$select({ key: "coupon_case_type", option: { labelWidth:'100px', seniorSearch: true, option: { multiple: true } }}),
          this.$select({ key: "coupon_take_out_type", option: { seniorSearch: true, option: { multiple: true } }}),
          this.$select({ key: "couponStatuse", option: { labelWidth:'100px', seniorSearch: true, option: { multiple: true } }}),
          
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: 'cancel',
            left: true,
            label: '作废',
            type: 'primary'
          },
          // {
          //   click: 'export',
          //   right: true,
          //   label: '导出',
          //   icon: 'el-icon-upload2',
          //   type: ''
          // },
          {
            click: "importFiles",
            right: true,
            label: "导入",
            icon: "el-icon-upload2",
            type: "",
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "couponCaseNo",
            label: "优惠券方案编号",
            minWidth: 200,
          },
          {
            prop: "couponCaseName",
            label: "优惠券方案名称",
            minWidth: 155,
          },
          {
            prop: "couponNo",
            label: "优惠券号",
            minWidth: 120,
          },
          {
            prop: "couponDesc",
            label: "优惠券内容",
            minWidth: 160,
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 160,
          },{
            prop: "vipNo",
            label: "会员卡号",
            minWidth: 160,
          },
          {
            prop: "tel",
            label: "手机号",
            minWidth: 160,
          },
          {
            prop: "couponCaseTypeName",
            label: "优惠券类型",
            minWidth: 160,
          },
          {
            prop: "takeOutTypeName",
            label: "发放方式",
            minWidth: 160,
          },
          {
            prop: "couponStatusName",
            label: "优惠券状态",
            minWidth: 160,
          },
          // {
          //   prop: "takeOutTime",
          //   label: "发放时间",
          //   minWidth: 160,
          // },
          {
            prop: "drawVipTime",
            label: "领用时间",
            minWidth: 160,
          },
          {
            prop: "sourceTypeName",
            label: "来源",
            minWidth: 160,
          },
          {
            prop: "validBegTime",
            label: "生效时间",
            minWidth: 160,
          },
          {
            prop: "validEndTime",
            label: "失效时间",
            minWidth: 160,
          },
          {
            prop: "remindAccountTime",
            label: "提醒到账时间",
            minWidth: 160,
          },
          {
            prop: "remindExpireTime",
            label: "提醒到期时间",
            minWidth: 160,
          },
        ],
        list: [],
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      //从首页待办业务进来
      console.log(to.query)
      if (to.query.couponCaseId && from.name == "GenerateCouppon") {
        vm.options.defaultBody = { addLogId: to.query.addLogId,couponCaseId:to.query.couponCaseId };
        vm.options.search = [];
        vm.options.getListApi = getPaperMarketingCouponBookList;
        vm.options.buttons = [];
        let cloneData = cloneDeep(vm.options);
        vm.options = null;
        vm.options = cloneData;
      }
    });
  },
  methods: {
    async handleEvent(type, row) {
      console.log(row, 'row')
      const selectData = this.options.check;
      switch (type) {
        case "importFiles":
        this.dialogOptions = {
            curType: 'coupon', //当前类型(门店/商品/类别/品牌)
            title: '文件导入',
            width: 600,
            show: true,
            type: 'ImportFile',
            data: {
              type: 'coupon', 
              monofile: true, 
              hearderList: [
                { originalName: "优惠券方案编号", curName: "优惠券方案编号" }, 
                { originalName: "优惠券方案名称", curName: "优惠券方案名称" },
                { originalName: "优惠券号", curName: "优惠券号" },
                { originalName: "会员卡号", curName: "会员卡号" },
                { originalName: "优惠券类型", curName: "优惠券类型"},
                { originalName: "优惠券形式", curName: "优惠券形式" },
                { originalName: "生效时间", curName: "生效时间" },
                { originalName: "失效时间", curName: "失效时间" }
              ],
              uploadApi: uploadFileCoupon,
              queryUploadApi: queryGetImportState
            }
          }
          break;
        case "export":
          console.log('导出没有这个功能')
          // this.handleBillStatus("导出", selectData);
          break;
        case "cancel":
          const couponIds = selectData.filter(v => v.couponStatus == 2).map((i) => i.couponId)
          if (couponIds.length == 0) {
            return  this.$message.error('请选择已出售的优惠券')
          }
          this.$confirm(`确认要作废已选中的${couponIds.length}张优惠券吗`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then( (res)=> {
            cancelMarketingCouponCaseBook(couponIds).then((res) => {
              this.$message.success(`作废${couponIds.length}张优惠券成功`)
              setTimeout(() => {
                this.$refs.tablePage.getList();
              }, 500);
            })
          })
          break;
        default:
          break;
      }
    },
    seniorSearch() {},
    handleBillStatus(command, selectData) {
      let name = "";
      if (command === "导出") {
        name = "导出";
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
