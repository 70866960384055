var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("topOperatingButton", {
        attrs: {
          showAudit: false,
          isSubmitAddBtn: false,
          isAuditBillBtn: false,
          disabled: _vm.isDisabled,
          id: "topOperatingButton",
        },
        on: {
          submitForm: function ($event) {
            return _vm.submitForm(false)
          },
          getQuit: _vm.getQuit,
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.boxLoading,
              expression: "boxLoading",
            },
          ],
          staticClass: "discount-scheme-detail",
          attrs: { "element-loading-text": "数据加载中" },
        },
        [
          _c(
            "div",
            { staticClass: "main" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "130px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "基本信息" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c("div", { staticClass: "basicInfo" }, [
                          _c("div", { staticClass: "basicInfoTop" }, [
                            _c("div", { staticClass: "left" }, [
                              _c("div", { staticClass: "imgTop" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "discountImg",
                                    class: {
                                      lightGreen: _vm.form.colorType === "1",
                                      darkGreen: _vm.form.colorType === "2",
                                      cambridgeBlue: _vm.form.colorType === "3",
                                      darkBlue: _vm.form.colorType === "4",
                                      purple: _vm.form.colorType === "5",
                                      brown: _vm.form.colorType === "6",
                                      yellow: _vm.form.colorType === "7",
                                      orange: _vm.form.colorType === "8",
                                      reddish: _vm.form.colorType === "9",
                                      red: _vm.form.colorType === "10",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "imgLeft" }, [
                                      _c("div", { staticClass: "content" }, [
                                        _vm.form.couponCaseType === "0"
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    "减" +
                                                      (_vm.form.parValue ||
                                                        "0") +
                                                      "元"
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.form.couponCaseType === "1"
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    "打" +
                                                      (_vm.form.parValue ||
                                                        "0") +
                                                      "折"
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.form.couponCaseType === "2"
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    "抵扣后" +
                                                      (_vm.form.parValue ||
                                                        "0") +
                                                      "元"
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _c("div", { staticClass: "tag x-bc" }, [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.form.couponCaseName ||
                                                  "国庆限时活动"
                                              )
                                            ),
                                          ]),
                                          _vm.form.sillType === "0"
                                            ? _c("span", [_vm._v("无门槛")])
                                            : _vm._e(),
                                          _vm.form.sillType === "1"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    "满" +
                                                      (_vm.form.sillMoney ||
                                                        "0") +
                                                      "元可使用"
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                        _c("div", { staticClass: "tag" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                (_vm.form.validBegTime ||
                                                  "2023-01-01 00:00:00") +
                                                  " ~ " +
                                                  (_vm.form.validEndTime ||
                                                    "2024-01-01 00:00:00")
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "imgRight" }, [
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v("立即领取"),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "colorBase x-f" }, [
                                _c("div", { staticClass: "colorLeft" }, [
                                  _vm._v("选择卡券颜色"),
                                ]),
                                _c("div", { staticClass: "colorRight" }, [
                                  _c("div", {
                                    staticClass: "coloritem lightGreen",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCardBg("1")
                                      },
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "coloritem darkGreen",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCardBg("2")
                                      },
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "coloritem cambridgeBlue",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCardBg("3")
                                      },
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "coloritem darkBlue",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCardBg("4")
                                      },
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "coloritem purple",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCardBg("5")
                                      },
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "coloritem brown",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCardBg("6")
                                      },
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "coloritem yellow",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCardBg("7")
                                      },
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "coloritem orange",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCardBg("8")
                                      },
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "coloritem reddish",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCardBg("9")
                                      },
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "coloritem red",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCardBg("10")
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "right" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "优惠券方案编号",
                                      prop: "couponCaseNo",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        size: "mini",
                                        placeholder: "自动生成",
                                      },
                                      model: {
                                        value: _vm.form.couponCaseNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "couponCaseNo",
                                            $$v
                                          )
                                        },
                                        expression: "form.couponCaseNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "优惠券方案名称",
                                      prop: "couponCaseName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { size: "mini" },
                                      model: {
                                        value: _vm.form.couponCaseName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "couponCaseName",
                                            $$v
                                          )
                                        },
                                        expression: "form.couponCaseName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "优惠券形式",
                                      prop: "couponMode",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          size: "mini",
                                          placeholder: "请选择",
                                          disabled: _vm.disCouponMode,
                                        },
                                        model: {
                                          value: _vm.form.couponMode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "couponMode",
                                              $$v
                                            )
                                          },
                                          expression: "form.couponMode",
                                        },
                                      },
                                      _vm._l(
                                        _vm.dict.type.coupon_mode,
                                        function (dict) {
                                          return _c("el-option", {
                                            key: dict.value,
                                            attrs: {
                                              label: dict.label,
                                              value: dict.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.form.couponCaseType === "0"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "面值",
                                          prop: "parValue",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "faceValue",
                                          attrs: {
                                            placeholder: "请输入",
                                            size: "mini",
                                          },
                                          model: {
                                            value: _vm.form.parValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "parValue",
                                                $$v
                                              )
                                            },
                                            expression: "form.parValue",
                                          },
                                        }),
                                        _c("span", [_vm._v("元")]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.form.couponCaseType === "1"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "折扣(%)",
                                          prop: "parValue",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "faceValue",
                                          attrs: {
                                            placeholder: "请输入",
                                            min: "1",
                                            max: "10",
                                            size: "mini",
                                          },
                                          model: {
                                            value: _vm.form.parValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "parValue",
                                                $$v
                                              )
                                            },
                                            expression: "form.parValue",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.form.couponCaseType === "2"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "金额",
                                          prop: "parValue",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "faceValue",
                                          attrs: {
                                            placeholder: "请输入",
                                            size: "mini",
                                          },
                                          model: {
                                            value: _vm.form.parValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "parValue",
                                                $$v
                                              )
                                            },
                                            expression: "form.parValue",
                                          },
                                        }),
                                        _c("span", [_vm._v("元")]),
                                        _c("span", { staticClass: "tag" }, [
                                          _vm._v("(抵扣后需支付的金额)"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "threshold" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "使用门槛",
                                          prop: "sillType",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            staticClass: "x-f",
                                            model: {
                                              value: _vm.form.sillType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "sillType",
                                                  $$v
                                                )
                                              },
                                              expression: "form.sillType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "0" } },
                                              [_vm._v("无使用门槛")]
                                            ),
                                            _vm.form.couponCaseType != "2"
                                              ? _c(
                                                  "el-radio",
                                                  { attrs: { label: "1" } },
                                                  [_vm._v("适用商品满")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.form.couponCaseType != "2" &&
                                    _vm.form.sillType === "0"
                                      ? _c(
                                          "el-form-item",
                                          { staticClass: "thresholdAmount" },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                disabled: "",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.form.couponCaseType != "2" &&
                                    _vm.form.sillType === "1"
                                      ? _c(
                                          "el-form-item",
                                          {
                                            staticClass: "thresholdAmount",
                                            attrs: { prop: "sillMoney" },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入",
                                                size: "mini",
                                              },
                                              model: {
                                                value: _vm.form.sillMoney,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "sillMoney",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.sillMoney",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.form.couponCaseType != "2"
                                      ? _c("div", { staticClass: "text" }, [
                                          _vm._v(" 元 "),
                                        ])
                                      : _vm._e(),
                                    _vm.form.couponCaseType === "0"
                                      ? _c("div", { staticClass: "giftCard" }, [
                                          _vm.form.sillType === "0"
                                            ? _c(
                                                "div",
                                                { staticClass: "tag" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        "(无门槛,减" +
                                                          (_vm.form.parValue ||
                                                            "xx") +
                                                          "元)"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.form.sillType === "1"
                                            ? _c(
                                                "div",
                                                { staticClass: "tag" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        "(满" +
                                                          (_vm.form.sillMoney ||
                                                            "xx") +
                                                          "元,减" +
                                                          (_vm.form.parValue ||
                                                            "xx") +
                                                          "元)"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _vm.form.couponCaseType === "1"
                                      ? _c(
                                          "div",
                                          { staticClass: "discountCard" },
                                          [
                                            _vm.form.sillType === "0"
                                              ? _c(
                                                  "div",
                                                  { staticClass: "tag" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          "(无门槛,打" +
                                                            (_vm.form
                                                              .parValue ||
                                                              "xx") +
                                                            "折)"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.form.sillType === "1"
                                              ? _c(
                                                  "div",
                                                  { staticClass: "tag" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          "(满" +
                                                            (_vm.form
                                                              .sillMoney ||
                                                              "xx") +
                                                            "元,打" +
                                                            (_vm.form
                                                              .parValue ||
                                                              "xx") +
                                                            "折)"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "有效时间",
                                      prop: "validTime",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "x-f" },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            size: "mini",
                                            type: "datetimerange",
                                            "range-separator": "至",
                                            "start-placeholder": "开始日期",
                                            "end-placeholder": "结束日期",
                                            "value-format":
                                              "yyyy-MM-dd HH:mm:ss",
                                            "default-time": [
                                              "00:00:00",
                                              "23:59:59",
                                            ],
                                            "picker-options": _vm.forbiddenTime,
                                          },
                                          model: {
                                            value: _vm.form.validTime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "validTime",
                                                $$v
                                              )
                                            },
                                            expression: "form.validTime",
                                          },
                                        }),
                                        _c("div", { staticClass: "tag" }, [
                                          _vm._v("(优惠券有效时间)"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("el-form-item", [
                                  _c(
                                    "div",
                                    { staticClass: "specialTime" },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          model: {
                                            value: _vm.form.isSpecalTime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "isSpecalTime",
                                                $$v
                                              )
                                            },
                                            expression: "form.isSpecalTime",
                                          },
                                        },
                                        [_vm._v("特殊领用时间")]
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _vm._v(" 领取后"),
                                          _c("el-input", {
                                            attrs: { size: "mini" },
                                            model: {
                                              value: _vm.form.drawDay,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "drawDay",
                                                  $$v
                                                )
                                              },
                                              expression: "form.drawDay",
                                            },
                                          }),
                                          _vm._v("天生效,生效后有效时长 "),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c("el-input", {
                                            attrs: { size: "mini" },
                                            model: {
                                              value: _vm.form.validDay,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "validDay",
                                                  $$v
                                                )
                                              },
                                              expression: "form.validDay",
                                            },
                                          }),
                                          _vm._v(" 天 "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "优惠券说明" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        placeholder: "请输入说明",
                                        size: "mini",
                                        maxlength: "300",
                                        "show-word-limit": "",
                                        rows: 3,
                                      },
                                      model: {
                                        value: _vm.form.couponDesc,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "couponDesc", $$v)
                                        },
                                        expression: "form.couponDesc",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "basicInfoBottom" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "适用渠道",
                                    prop: "AllowOfflineShop",
                                  },
                                },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      model: {
                                        value: _vm.form.AllowOfflineShop,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "AllowOfflineShop",
                                            $$v
                                          )
                                        },
                                        expression: "form.AllowOfflineShop",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        { attrs: { label: "0" } },
                                        [_vm._v("线下门店")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        { attrs: { label: "1" } },
                                        [_vm._v("线上商城")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "适用门店",
                                    prop: "shopRangeType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.form.shopRangeType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "shopRangeType",
                                            $$v
                                          )
                                        },
                                        expression: "form.shopRangeType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.dict.type.fill_shop_range_type,
                                      function (dict) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: dict.value,
                                            attrs: {
                                              label: dict.value,
                                              value: dict.value,
                                            },
                                          },
                                          [_vm._v(_vm._s(dict.label))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.form.shopRangeType === "1" ||
                              _vm.form.shopRangeType === "2"
                                ? _c(
                                    "div",
                                    { staticClass: "shopTable" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "btn x-f marB10" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { size: "mini" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEvent(
                                                    "openShopDialog",
                                                    { curTable: "shopOptions" }
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("选择门店")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("EditTable", {
                                        attrs: { options: _vm.shopOptions },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.form.couponCaseType === "0" ||
                              _vm.form.couponCaseType === "1"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "适用商品",
                                        prop: "goodsRangeType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: _vm.form.goodsRangeType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "goodsRangeType",
                                                $$v
                                              )
                                            },
                                            expression: "form.goodsRangeType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.dict.type.vip_goods_range_type,
                                          function (dict) {
                                            return _c(
                                              "el-radio",
                                              {
                                                key: dict.value,
                                                attrs: {
                                                  label: dict.value,
                                                  value: dict.value,
                                                },
                                              },
                                              [_vm._v(_vm._s(dict.label))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.form.couponCaseType === "2"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "抵扣商品",
                                        prop: "deductionGoodsType",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "x-f" },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: { input: _vm.labelChange },
                                              model: {
                                                value:
                                                  _vm.form.deductionGoodsType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "deductionGoodsType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.deductionGoodsType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.dict.type
                                                .deduction_goods_type,
                                              function (dict) {
                                                return _c(
                                                  "el-radio",
                                                  {
                                                    key: dict.value,
                                                    attrs: {
                                                      label: dict.value,
                                                      value: dict.value,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(dict.label))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                          _vm.form.deductionGoodsType === "0"
                                            ? _c("el-input", {
                                                staticClass:
                                                  "deductionGoodsTypeDis",
                                                attrs: {
                                                  disabled: "",
                                                  size: "mini",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.form.deductionGoodsType === "1"
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "deductionGoodsType",
                                                  attrs: {
                                                    prop: "deductionGoodsPiece",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: { size: "mini" },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .deductionGoodsPiece,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "deductionGoodsPiece",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.deductionGoodsPiece",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c("div", [_vm._v("件")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.form.goodsRangeType === "1" ||
                              _vm.form.goodsRangeType === "2" ||
                              (_vm.form.deductionGoodsType === "0" &&
                                _vm.form.couponCaseType === "2") ||
                              (_vm.form.deductionGoodsType === "1" &&
                                _vm.form.couponCaseType === "2")
                                ? _c(
                                    "div",
                                    { staticClass: "goodsTable" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "btn x-f marB10" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { size: "mini" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEvent(
                                                    "openGoodsDialog",
                                                    { curTable: "goodsOptions" }
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("选择商品")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.form.couponCaseType !==
                                                    "2",
                                                  expression:
                                                    "form.couponCaseType !== '2'",
                                                },
                                              ],
                                              attrs: { size: "mini" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEvent(
                                                    "openCategoryDialog",
                                                    { curTable: "goodsOptions" }
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("选择类别")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.form.couponCaseType !==
                                                    "2",
                                                  expression:
                                                    "form.couponCaseType !== '2'",
                                                },
                                              ],
                                              attrs: { size: "mini" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEvent(
                                                    "openBrandDialog",
                                                    { curTable: "goodsOptions" }
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("选择品牌")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("EditTable", {
                                        attrs: { options: _vm.goodsOptions },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "x-x" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "发放对象",
                                        prop: "AllowVip",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          on: { change: _vm.handleCheckVip },
                                          model: {
                                            value: _vm.form.AllowVip,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "AllowVip",
                                                $$v
                                              )
                                            },
                                            expression: "form.AllowVip",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-checkbox",
                                            { attrs: { label: "0" } },
                                            [_vm._v("全部会员")]
                                          ),
                                          _c(
                                            "el-checkbox",
                                            { attrs: { label: "1" } },
                                            [_vm._v("全部会员+排除")]
                                          ),
                                          _c(
                                            "el-checkbox",
                                            { attrs: { label: "2" } },
                                            [_vm._v("指定会员")]
                                          ),
                                          _c(
                                            "el-checkbox",
                                            { attrs: { label: "4" } },
                                            [_vm._v("生日会员")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.showVipBD
                                    ? _c(
                                        "el-select",
                                        {
                                          staticClass: "selectVipBD",
                                          attrs: { size: "mini" },
                                          model: {
                                            value: _vm.form.vipBirthdayType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "vipBirthdayType",
                                                $$v
                                              )
                                            },
                                            expression: "form.vipBirthdayType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.dict.type.vip_birthday_type,
                                          function (dict) {
                                            return _c("el-option", {
                                              key: dict.value,
                                              attrs: {
                                                label: dict.label,
                                                value: dict.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.vipTable
                                ? _c(
                                    "div",
                                    { staticClass: "vipTable" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "btn x-f marB10" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { size: "mini" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEvent(
                                                    "openVipDialog",
                                                    { curTable: "vipOptions" }
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("选择会员")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { size: "mini" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEvent(
                                                    "openVipLevelDialog",
                                                    { curTable: "vipOptions" }
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("选择会员级别")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("EditTable", {
                                        attrs: { options: _vm.vipOptions },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "适用时间",
                                    prop: "useTimeRangeType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.form.useTimeRangeType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "useTimeRangeType",
                                            $$v
                                          )
                                        },
                                        expression: "form.useTimeRangeType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.dict.type.use_time_range_type,
                                      function (dict) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: dict.value,
                                            attrs: {
                                              label: dict.value,
                                              value: dict.value,
                                            },
                                          },
                                          [_vm._v(_vm._s(dict.label))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.form.useTimeRangeType === "2"
                                ? _c(
                                    "div",
                                    { staticClass: "timeTable" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "btn x-f marB10" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { size: "mini" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEvent(
                                                    "openTimeDateDialog",
                                                    {
                                                      curTable:
                                                        "timeDateOptions",
                                                    }
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("选择时间")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("EditTable", {
                                        attrs: { options: _vm.timeDateOptions },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "销售设置",
                                    prop: "isEnableSale",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "x-x" },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.form.couponMode == "1",
                                          },
                                          model: {
                                            value: _vm.form.isEnableSale,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "isEnableSale",
                                                $$v
                                              )
                                            },
                                            expression: "form.isEnableSale",
                                          },
                                        },
                                        [_vm._v("支持销售")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "x-x marL15" },
                                        [
                                          _vm._v(" 售价 "),
                                          !_vm.form.isEnableSale
                                            ? _c("el-input", {
                                                staticClass: "inputW100",
                                                attrs: {
                                                  disabled: "",
                                                  size: "mini",
                                                },
                                              })
                                            : _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    "label-width": "0",
                                                    prop: "salePrice",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticClass: "inputW100",
                                                    attrs: { size: "mini" },
                                                    model: {
                                                      value: _vm.form.salePrice,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "salePrice",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.salePrice",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                          _vm._v(" 元 "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "领取限制" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c(
                          "div",
                          { staticClass: "getRestrict" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "优惠券总量",
                                  prop: "couponCount",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.form.couponCount,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "couponCount", $$v)
                                    },
                                    expression: "form.couponCount",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "每人限领次数",
                                  prop: "limitType",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "0" },
                                    model: {
                                      value: _vm.form.limitType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "limitType", $$v)
                                      },
                                      expression: "form.limitType",
                                    },
                                  },
                                  [_vm._v("不限次数")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "x-x" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "frequency",
                                    attrs: { prop: "limitType" },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "1" },
                                        model: {
                                          value: _vm.form.limitType,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "limitType", $$v)
                                          },
                                          expression: "form.limitType",
                                        },
                                      },
                                      [_vm._v(_vm._s(""))]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.form.limitType === "0"
                                  ? _c("el-input", {
                                      staticClass: "limitCountDis",
                                      attrs: { disabled: "", size: "mini" },
                                    })
                                  : _vm._e(),
                                _vm.form.limitType === "1"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        staticClass: "num",
                                        attrs: { prop: "limitCount" },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { size: "mini" },
                                          model: {
                                            value: _vm.form.limitCount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "limitCount",
                                                $$v
                                              )
                                            },
                                            expression: "form.limitCount",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "countText" }, [
                                  _vm._v("次"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "转赠设置" } },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { label: "允许转赠" },
                                    model: {
                                      value: _vm.form.isCanGive,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "isCanGive", $$v)
                                      },
                                      expression: "form.isCanGive",
                                    },
                                  },
                                  [_vm._v("允许转赠")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "其他限制" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c(
                          "div",
                          { staticClass: "otherRestrictions" },
                          [
                            _vm.form.couponCaseType == "0"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "叠加设置" } },
                                      [
                                        _c("el-checkbox", {
                                          attrs: { label: "同方案叠加" },
                                          model: {
                                            value:
                                              _vm.form
                                                .isAllowSameCouponCaseReagio,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "isAllowSameCouponCaseReagio",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.isAllowSameCouponCaseReagio",
                                          },
                                        }),
                                        _c("el-checkbox", {
                                          attrs: { label: "同类型叠加" },
                                          model: {
                                            value:
                                              _vm.form
                                                .isAllowSameCouponTypeReagio,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "isAllowSameCouponTypeReagio",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.isAllowSameCouponTypeReagio",
                                          },
                                        }),
                                        _c("el-checkbox", {
                                          attrs: { label: "折扣券叠加" },
                                          model: {
                                            value:
                                              _vm.form.isAllowAgioCouponReagio,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "isAllowAgioCouponReagio",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.isAllowAgioCouponReagio",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.form.isAllowSameCouponCaseReagio ||
                                    _vm.form.isAllowSameCouponTypeReagio
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "el-form-item",
                                              [
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .isLimitSameCouponReagio,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "isLimitSameCouponReagio",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.isLimitSameCouponReagio",
                                                    },
                                                  },
                                                  [_vm._v("叠加限制")]
                                                ),
                                                _c(
                                                  "el-radio",
                                                  {
                                                    staticClass: "limitQty",
                                                    attrs: { label: 1 },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .limitSameCouponReagioType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "limitSameCouponReagioType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.limitSameCouponReagioType",
                                                    },
                                                  },
                                                  [_vm._v("数量限制")]
                                                ),
                                                _vm.form
                                                  .limitSameCouponReagioType !=
                                                1
                                                  ? _c("el-input", {
                                                      staticClass: "inputW100",
                                                      attrs: {
                                                        disabled: "",
                                                        size: "mini",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm.form
                                                  .limitSameCouponReagioType ===
                                                1
                                                  ? _c("el-input", {
                                                      staticClass: "inputW100",
                                                      attrs: { size: "mini" },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .limitSameCouponReagioCount,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "limitSameCouponReagioCount",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.limitSameCouponReagioCount",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.form.limitSameCouponReagioType == 2
                                      ? _c("el-form-item")
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.form.couponCaseType == "1"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "叠加设置" } },
                                      [
                                        _c("el-checkbox", {
                                          attrs: { label: "代金券叠加" },
                                          model: {
                                            value:
                                              _vm.form.isAllowCashCouponReagio,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "isAllowCashCouponReagio",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.isAllowCashCouponReagio",
                                          },
                                        }),
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: 0 },
                                            model: {
                                              value:
                                                _vm.form.cashCouponReagioType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "cashCouponReagioType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.cashCouponReagioType",
                                            },
                                          },
                                          [_vm._v("先折扣再减现")]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: 1 },
                                            model: {
                                              value:
                                                _vm.form.cashCouponReagioType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "cashCouponReagioType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.cashCouponReagioType",
                                            },
                                          },
                                          [_vm._v("先减现再折扣")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.form.couponCaseType == "2"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "叠加设置" } },
                                      [
                                        _c("div", { staticClass: "tag" }, [
                                          _vm._v(
                                            " 本方案的抵扣券默认可跟其他方案的抵扣券、代金券、折扣券叠加使用，先抵扣后再根据折扣券的减折规则计算收款金额 "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              { attrs: { label: "其他限制" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "x-f",
                                    staticStyle: { height: "36px" },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 0 },
                                        model: {
                                          value: _vm.form.limitShareType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "limitShareType",
                                              $$v
                                            )
                                          },
                                          expression: "form.limitShareType",
                                        },
                                      },
                                      [_vm._v("不可与其它优惠共享")]
                                    ),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 1 },
                                        model: {
                                          value: _vm.form.limitShareType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "limitShareType",
                                              $$v
                                            )
                                          },
                                          expression: "form.limitShareType",
                                        },
                                      },
                                      [_vm._v("可与其它优惠共享")]
                                    ),
                                    _c("el-checkbox", {
                                      attrs: {
                                        label: "改价",
                                        disabled: _vm.form.limitShareType != 1,
                                      },
                                      model: {
                                        value: _vm.form.isLimitShareChangePrice,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "isLimitShareChangePrice",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.isLimitShareChangePrice",
                                      },
                                    }),
                                    _c("el-checkbox", {
                                      attrs: {
                                        label: "促销",
                                        disabled: _vm.form.limitShareType != 1,
                                      },
                                      model: {
                                        value: _vm.form.isLimitSharePromoCase,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "isLimitSharePromoCase",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.isLimitSharePromoCase",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "优惠券支付设置" } },
                              [
                                _c("el-checkbox", {
                                  attrs: { label: "允许会员储值支付" },
                                  model: {
                                    value: _vm.form.isAllowVipPay,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isAllowVipPay", $$v)
                                    },
                                    expression: "form.isAllowVipPay",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("Dialog", {
                ref: "tablePage",
                attrs: { options: _vm.dialogOptions },
                on: {
                  "update:options": function ($event) {
                    _vm.dialogOptions = $event
                  },
                  handleEvent: _vm.handleEvent,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }