<template>
  <div class="instore-detail" v-loading="loading">
    <div class="card">
      <topOperatingButton
        :disabled="disabled"
        :showAudit="showAudit"
        :isAddBtn="showAdd"
        @submitForm="handleSubmit(false)"
        @addBill="handleSubmit(true)"
        @auditBill="changeBillStatus"
        @handleAdd="handleAdd"
        @getQuit="getQuit"
        id="topOperatingButton"
      >
        <template slot="specialDiyBtn">
          <el-button
            icon="el-icon-remove-outline"
            size="mini"
            :disabled="single"
            @click="changeBillStatus('关闭')"
            >终止单据
          </el-button>
        </template>
      </topOperatingButton>
      <div class="discount-scheme-detail" element-loading-text="数据加载中">
        <!-- 填写明细表单区域 -->
        <el-form
          ref="form"
          :model="form"
          label-width="90px"
          label-position="right"
          class="ken-detail-form"
          :rules="rules"
        >
          <cardTitleCom
            cardTitle="基本信息"
            :billStatus="form.billStatus"
            id="basicInformation"
          >
            <template slot="cardContent">
              <div class="x-f marT10">
                <el-form-item label="单据编号" prop="billNo">
                  <el-input
                    v-model="form.billNo"
                    placeholder="单据编号"
                    disabled
                    size="mini"
                  ></el-input>
                </el-form-item>

                <el-form-item label="单据日期" prop="billDate">
                  <el-date-picker
                    v-model="form.billDate"
                    type="date"
                    placeholder="选择日期"
                    :disabled="disabled"
                    value-format="yyyy-MM-dd"
                    size="mini"
                  >
                  </el-date-picker>
                </el-form-item>

                <el-form-item label="领出部门" prop="receiveDeptId">
                  <el-select
                    class="billType marR5"
                    v-model="form.receiveDeptId"
                    placeholder="请选择"
                    filterable
                    clearable
                    :disabled="disabled"
                    size="mini"
                  >
                    <el-option
                      v-for="item in deptData"
                      :key="item.deptId"
                      :label="item.deptName"
                      :value="item.deptId"
                    />
                  </el-select>
                </el-form-item>

                <el-form-item label="领出人员" prop="receiveEmployeeId">
                  <el-select
                    v-model="form.receiveEmployeeId"
                    placeholder="请选择"
                    :disabled="disabled"
                    filterable
                    size="mini"
                    remote
                    reserve-keyword
                  >
                    <el-option
                      v-for="item in employeeData"
                      :key="item.employeeId"
                      :label="item.employeeName"
                      :value="item.employeeId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="收款方式" prop="payModeId">
                  <SelectLocal
                    v-model="form.payModeId"
                    :disabled="disabled"
                    :option="{ data: playList, value: 'payModeId', label: 'payModeName' }"
                  />
                </el-form-item>

                <el-form-item label="预收金额" prop="payMoney">
                  <el-input
                    v-model="form.payMoney"
                    :disabled="disabled"
                    placeholder="预收金额"
                    size="mini"
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                  ></el-input>
                </el-form-item>
              </div>
            </template>
          </cardTitleCom>

          <!-- 表格商品信息卡片 -->
          <cardTitleCom cardTitle="商品信息" class="headGoodsTable">
            <template slot="rightCardTitle">
              <div class="marR10"></div>
            </template>
            <template slot="cardContent">
              <div class="instore-table">
                <el-row>
                  <el-col :span="24">
                    <keyBoardTable
                      ref="myTable"
                      :key="tableKey"
                      :isDisabled="disabled"
                      :tableHeight="'510px'"
                      :isShowCheckbox="true"
                      :tableColumns="originColumns"
                      :tableColumnsCopy="originColumnsCopy"
                      @handleSelectionChange="handleSelectionChange"
                      @handleTableBlur="handleTableBlur"
                      @handleTableInput="handleTableInput"
                      @handleClickDefineInputIcon="handleClickDefineInputIcon"
                      @handleTableSelectChange="handleTableSelectChange"
                      @handleSyncTableData="handleSyncTableData"
                      @handleTableCallback="handleTableCallback"
                    ></keyBoardTable>
                  </el-col>
                </el-row>
              </div>
            </template>
          </cardTitleCom>

          <div class="instore-footer">
            <!-- 附件上传组件 -->
            <accessoryUpload
              listType="img"
              :disabled="disabled"
              :fileList="form.fileItems"
              @getFileItems="getFileItemsData"
              @delFileItems="delFileItems"
            ></accessoryUpload>
            <div class="right-remark">
              <span> 单据备注:</span>
              <el-input
                class="input"
                placeholder="备注"
                :disabled="disabled"
                type="textarea"
                :rows="3"
                v-model="form.billRemark"
              ></el-input>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 弹窗 -->
    <SelectCouponStream
      :openDialog.sync="openDialog"
      contentType="receive"
      @handleConfirmData="getPitchData"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getNewDate } from "@/utils/newDate"; //单据日期
import { shopBasPayList } from "@/api/O2OMall/orderManage/order.js";
import { allEmployeeList } from "@/api/system/employee";
import { listDept } from "@/api/system/dept";
import {
  couponReceiveSave,
  couponReceiveDetail,
  couponReceiveUpdate,
  updateBillStatus,
} from "@/api/marketing/coupon/receive";
import { getNewBillNo } from "@/utils/numFormatting";
import {
  scroll,
  getCurTime,
  delFileItems,
  getFileItemsData,
  getDiyselectId,
  getBasicData,
} from "@/views/components/bill/public-layout.js"; //方法统一存放的地方

import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import SelectCouponStream from "@/views/components/selectCouponStream"; //门店资料
import Dialog from "@/components/Dialog";
import keyBoardTable from "@/components/tablePage/keyBoardTable/index.vue";
import accessoryUpload from "@/views/components/accessoryUpload";
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import { arrayObjectCopy } from "@/utils";

export default {
  name: "CouponReceipveDetail",
  components: {
    cardTitleCom,
    topOperatingButton,
    Dialog,
    keyBoardTable,
    accessoryUpload,
    SelectCouponStream,
    SelectLocal,
  },
  data() {
    return {
      ids: [],
      multiple: true,
      //打印
      PrintObj: {
        id: "print",
      },
      //更新单据状态url
      urlBillStatus: undefined,
      //单据id
      billId: undefined,
      //遮罩层
      loading: false,
      //禁用标识
      disabled: false,
      //标识已审核
      auditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识未审核
      noAuditOrder: false,
      //标识已删除
      deleteOrder: false,
      //是否启用审核按钮
      showAudit: true,
      //员工数据
      employeeData: [],
      //部门数据
      deptData: [],
      //表单数据
      form: {
        //单据id
        billId: undefined,
        //制单日期
        billDate: undefined,
        //单据编号
        billNo: undefined,
        receiveDeptId: undefined,
        receiveEmployeeId: undefined,
        payModeId: undefined,
        payMoney: undefined,
        //单据状态
        billStatus: "",
        //备注
        billRemark: undefined,
        //单据表格-订单明细
        details: [{}],
        //上传的附件
        fileItems: [],
      },
      fileList: [], // 显示的文件
      //表单校验
      rules: {
        billNo: [{ required: true, message: "请到编码规则设置", trigger: "blur" }],
        billDate: [{ required: true, message: "请输入单据日期", trigger: "blur" }],
        receiveDeptId: [
          {
            required: true,
            message: "请选择领出部门",
            trigger: ["change", "blur"],
          },
        ],
        receiveEmployeeId: [
          {
            required: true,
            message: "请选择领出人员",
            trigger: ["change", "blur"],
          },
        ],
        payModeId: [
          {
            required: true,
            message: "请选择收款方式",
            trigger: ["change", "blur"],
          },
        ],
        payMoney: [
          {
            required: true,
            message: "请输入预收金额",
            trigger: ["change", "blur"],
          },
        ],
      },
      keepStatus: false,
      //关闭单据
      single: true,
      //新增按钮
      showAdd: false,
      openDialog: false,
      originColumns: [
        {
          fieldCaption: "类型编号",
          fieldCustomCaption: "类型编号",
          fieldName: "couponCaseNo",
          fieldCustomWidth: 200,
          fieldIsDisplay: true,
          type: "inputText",
          isRequired: true,
          icon: "el-icon-more",
        },
        {
          fieldCaption: "类型名称",
          fieldCustomCaption: "类型名称",
          fieldName: "couponCaseName",
          fieldCustomWidth: 200,
          fieldIsDisplay: true,
        },
        {
          fieldCaption: "开始券号",
          fieldCustomCaption: "开始券号",
          fieldName: "begGlideNo",
          fieldCustomWidth: 200,
          fieldIsDisplay: true,
          type: "inputNumber",
          intMax: 6,
        },
        {
          fieldCaption: "结束券号",
          fieldCustomCaption: "结束券号",
          fieldName: "endGlideNo",
          fieldCustomWidth: 200,
          fieldIsDisplay: true,
        },
        {
          fieldCaption: "领取(张)",
          fieldCustomCaption: "领取(张)",
          fieldName: "unitQty",
          fieldCustomWidth: 160,
          fieldIsDisplay: true,
          type: "inputNumber",
          isRequired: true,
        },
        {
          fieldCaption: "销售价格",
          fieldCustomCaption: "销售价格",
          fieldName: "unitPrice",
          fieldCustomWidth: 160,
          fieldIsDisplay: true,
          type: "inputNumber",
          isRequired: true,
        },
        {
          fieldCaption: "折扣(%)",
          fieldCustomCaption: "折扣(%)",
          fieldName: "agioRate",
          fieldCustomWidth: 160,
          fieldIsDisplay: true,
          type: "inputNumber",
          intMax: 3,
        },
        {
          fieldCaption: "销售金额",
          fieldCustomCaption: "销售金额",
          fieldName: "unitMoney",
          fieldCustomWidth: 160,
          fieldIsDisplay: true,
        },
        {
          fieldCaption: "备注",
          fieldCustomCaption: "备注",
          fieldName: "remark",
          fieldCustomWidth: 160,
          fieldIsDisplay: true,
          type: "inputText",
        },
      ], //原始列表数据
      originColumnsCopy: [],
      tableKey: Date.now(),
      gridColumns: [],
      billType: "",
      playList: [],
      sourceStatus: false,
      //自定义搜索框字段转换 (客户)
      normalizerCus: {
        Id: "customerId",
        No: "customerNo",
        Name: "customerName",
      },
      originPitchData: [],
      TypeBill: "160203",
      chooseIndex: undefined,
    };
  },
  filters: {
    //过滤3位小数,并四舍五入
    numFilter(value) {
      let realVal;
      if (value) {
        realVal = parseFloat(value).toFixed(3);
      } else {
        realVal = "";
      }
      return realVal;
    },
    numFilterFun(value) {
      let realVal;
      if (value) {
        // realVal = parseFloat(value).toFixed(8)
        realVal = Number(value.toFixed(8));
      } else {
        realVal = "";
      }
      return realVal;
    },
  },
  watch: {
    //侦听传过来的单据类型
    billType: {
      handler(newVal) {
        this.originColumnsCopy = arrayObjectCopy(this.originColumns);
        if (this.$route.query.type === "Update") {
          if (this.$route.query.billId) {
            this.billId = this.$route.query.billId;
          }
        } else {
          this.$nextTick(() => {
            this.getNewBillNo(this.form, "billNo", Number(this.TypeBill));
          });
        }
      },
      immediate: true,
    },
    //侦听是否开启关闭按钮
    "form.billStatus": {
      handler(newVal) {
        if (this.form.billStatus === "2") {
          //新增按钮
          this.showAdd = true;

          //打开关闭按钮
          this.single = false;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = false;
        } else if (this.form.billStatus === "3") {
          //新增按钮
          this.showAdd = false;
          //禁用关闭按钮
          this.single = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //禁用审核按钮
          this.showAudit = true;
        } else if (this.form.billStatus === "0") {
          //新增按钮
          this.showAdd = false;
          //打开输入按钮、保存按钮
          this.disabled = false;
          //禁用关闭按钮
          this.single = true;

          //禁用审核按钮
          this.showAudit = false;
        } else if (this.form.billStatus === "4") {
          //新增按钮
          this.showAdd = false;

                    //禁用关闭按钮
                    this.single = true;
                    //禁用输入按钮、保存按钮
                    this.disabled = true;
                    //禁用审核按钮
                    this.showAudit = true;
                } else if (this.form.billStatus === "") {
                    //新增按钮
                    this.showAdd = false;
                    //禁用审核按钮
                    this.showAudit = true;
                    //禁用关闭按钮
                    this.single = true;
                    //打开输入按钮、保存按钮
                    this.disabled = false;
                }
                this.$refs.myTable?.handleCallbackData([]);
                this.originColumns.forEach((item) => {
                    item.disabled = this.disabled;
                });
                this.$refs.myTable?.handleCallbackData(this.form.details);
            },
            immediate: true,
        },
        'form.details': {
            handler(val) {
                if (val && val.length > 0 && this.$refs.myTable) {
                    this.$refs.myTable.form.details = val || [];
                }
            },
            immediate: true
        },
    },
    deactivated() {
        this.handleAdd();
        this.disabled = false;
        this.auditOrder = false;
        this.stopOrder = false;
        this.noAuditOrder = false;
        this.deleteOrder = false;
        this.getCurTime();
        this.getNewBillNo(this.form, "billNo", Number(this.TypeBill));
    },
    activated() {
        if (this.keepStatus) return;
        this.handleBasicData();
        if (this.$route.query.type === "Update") {
            if (this.$route.query.billId) {
                this.billId = this.$route.query.billId;
                this.getBillDetail(this.billId);
            }
        } else {
            this.getCurTime();
        }
    },
    created() {
        this.handleBasicData();
        if (this.$route.query.type === "Update") {
            if (this.$route.query.billId) {
                this.billId = this.$route.query.billId;
                this.getBillDetail(this.billId);
            }
        } else {
            this.getCurTime();
        }
    },
    mounted() {
        this.getCurTime();
        this.scroll(); // 监听表格滚动条滚动事件
    },
    computed: {
        isUpdate() {
            return this.$route.query.type === "Update";
        },
    },
    methods: {
        scroll,
        delFileItems,
        getFileItemsData,
        getCurTime,
        getDiyselectId,
        getBasicData,
        //新增时获取单据编号
        getNewBillNo,
        addWithLeadingZeros(num1, num2) {
            // 将数字转换为字符串
            num1 = num1.toString();
            num2 = num2.toString();
            // 执行加法
            let sum = (parseInt(num1) + parseInt(num2)).toString();
            // 返回保留前导零的结果
            return sum.padStart(Math.max(num1.length, num2.length), '0');
        },
        subtractWithLeadingZeros(num1, num2) {
            // 将数字转换为字符串
            num1 = num1.toString();
            num2 = num2.toString();
            // 执行减法
            let difference = (parseInt(num1) - parseInt(num2)).toString();
            // 返回保留前导零的结果
            return difference.padStart(Math.max(num1.length, num2.length), '0');
        },
        //选择优惠券流水号
        getPitchData(data) {
            console.log(data)
            let arr = data.map((x) => ({
                ...x,
                endGlideNo:this.addWithLeadingZeros(x.begGlideNo, Number(x.unitQty)-1),
                unitPrice: x.salePrice,
                unitMoney: this.$syInput(3, x.salePrice * x.unitQty),
                unitSalePrice: x.salePrice
            }));
            this.form.details = this.form.details.filter((item, index) => index !== this.chooseIndex);

            this.form.details = [...this.form.details, ...arr];
            this.originPitchData = arrayObjectCopy(data);
        },
        handleSelectionChange(selection) {
            this.ids = selection
                .map((item) => item.goodsId)
                .filter((ite) => typeof ite !== "undefined"); //单据id
            this.multiple = !this.ids.length;
        },
        async handleBasicData() {
            try {
                let { rows } = await shopBasPayList({ isUse: true })
                this.playList = rows.filter(i => i.isDisplay)

        //获取员工数据
        allEmployeeList().then((response) => {
          this.employeeData = response.data;
        });

        //获取部门数据
        listDept({
          pageNum: 1,
          pageSize: 1000,
        }).then((response) => {
          this.deptData = response.data;
        });
      } catch (e) {
        console.log(e);
      }
    },
    async getBillDetail(billId) {
      let res = await couponReceiveDetail(billId);
      console.log(res);
      this.form = res.data;
      this.form.details = res.data.billDetailItems;
    },
    async handleAdd() {
      //console.log(this.form.billStatus, "form.billStatus ", this.billType);
      this.form = {
        //单据id
        billId: undefined,
        //制单日期
        billDate: undefined,
        //单据编号
        billNo: undefined,
        receiveDeptId: undefined,
        receiveEmployeeId: undefined,
        payModeId: undefined,
        payMoney: undefined,
        //单据状态
        billStatus: "",
        //备注
        billRemark: undefined,
        //单据表格-订单明细
        details: [{}],
        //上传的附件
        fileItems: [],
      };
      this.getNewBillNo(this.form, "billNo", Number(this.TypeBill));
      this.getCurTime();
    },
    //退出
    getQuit() {
      this.$router.push({
        path: "/marketing/coupon/receive",
      });
    },
    async changeBillStatus(name, bool) {
      try {
        await this.$confirm(`确定${name}该单据, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });
        this.loading = true;
        if (name === "审核") {
          if (this.form.billStatus != "0") {
            this.$message.error("此单据不是未审核的状态哦~");
            return;
          }
          await this.submitForm();
          let response = await updateBillStatus([this.form.billId], "2");
          this.form.billStatus = response.data.billStatus;

                    this.disabled = true; //是否禁用
                    this.auditOrder = this.form.billStatus; //审核图标
                    this.noAuditOrder = false; //未审核图标
                    // 弹出提示
                    this.$message.success(`${name}单据成功`);

                } else if (name === "反审核") {
                    let response = await updateBillStatus(
                        [this.form.billId],
                        "0",
                    );
                    this.form.billStatus = response.data.billStatus;
                    this.disabled = false;
                    this.auditOrder = false;
                    this.noAuditOrder = true;
                    // 弹出提示
                    this.$message.success(`${name}单据成功`);
                } else if (name === "关闭") {
                    //console.log("审核8 == ", this.auditOrder);
                    updateBillStatus(
                        [this.form.billId],
                        "4",
                    ).then((response) => {
                        this.form.billStatus = response.data.billStatus;
                        //this.form = response.data;
                        this.disabled = true;
                        this.showAudit = true;
                        this.stopOrder = true;
                        // 弹出提示
                        this.$message.success(`${name}单据成功`);
                    });
                }
                this.loading = false;
                this.$refs.myTable?.handleCallbackData([]);
                this.originColumns.forEach((item) => {
                    item.disabled = this.disabled;
                });
                this.$refs.myTable?.handleCallbackData(this.form.details);
            } catch {
                this.loading = false;
            }
        },
        //表格数据更新回调
        handleSyncTableData(val) {
            this.form.details = val;
        },
        //表格数据选中回调
        handleTableSelectChange(e, scope, item) {
        },
        //表格文本框失焦回调
        handleTableBlur(e, scope, item) {
            let value = e.target.value;
            if (item.key == 'begGlideNo') {
                if (value.length != 6 || Number(value) < this.originPitchData[scope.rowIndex].begGlideNo || Number(value) > this.originPitchData[scope.rowIndex].endGlideNo) {
                    this.$message.warning('输入数值不正确！');
                    console.log(this.originPitchData)
                    this.$set(this.form.details[scope.rowIndex], 'begGlideNo', this.originPitchData[scope.rowIndex].begGlideNo);
                    return;
                }
                let endGlideNo = this.addWithLeadingZeros(this.form.details[scope.rowIndex].begGlideNo,Number(this.form.details[scope.rowIndex].unitQty)-1);
                if(Number(endGlideNo) > this.originPitchData[scope.rowIndex].endGlideNo){
                    this.$message.warning('该流水号范围内的优惠券券有部分不是在库状态无法使用!');
                    let originBe = this.subtractWithLeadingZeros(this.form.details[scope.rowIndex].endGlideNo,Number(this.form.details[scope.rowIndex].unitQty)-1);
                    this.$set(this.form.details[scope.rowIndex], 'begGlideNo', originBe);
                    return;
                }
                this.$set(this.form.details[scope.rowIndex], 'endGlideNo', endGlideNo);
            }
            if (item.key == 'unitQty') {
                if (Number(value) == 0) {
                    this.$message.warning('输入数值不能等于0');
                    this.$set(this.form.details[scope.rowIndex], 'unitQty', this.originPitchData[scope.rowIndex].unitQty);
                    this.handleCalc(this.form.details[scope.rowIndex],scope.rowIndex);
                }
                if (Number(value) > this.originPitchData[scope.rowIndex].usableQty) {
                    this.$message.warning('输入数值不能大于剩余张数：'+this.originPitchData[scope.rowIndex].usableQty);
                    this.$set(this.form.details[scope.rowIndex], 'unitQty', this.originPitchData[scope.rowIndex].unitQty);
                    this.handleCalc(this.form.details[scope.rowIndex],scope.rowIndex);
                }
                let endGlideNo = this.addWithLeadingZeros(this.form.details[scope.rowIndex].begGlideNo,Number(this.form.details[scope.rowIndex].unitQty)-1);
                this.$set(this.form.details[scope.rowIndex], 'endGlideNo', endGlideNo);
            }
        },
        handleTableInput(e, scope, item) {
            if (item.key == 'unitQty' || item.key == 'unitPrice' || item.key == 'agioRate') {
                this.handleCalc(scope.row,scope.rowIndex);
            }
        },
        //计算金额
        handleCalc(row,index){
            if (row.unitQty && row.unitPrice) {
                let result = row.unitQty * row.unitPrice;
                if(row.agioRate){
                    result = row.unitQty * row.unitPrice * (row.agioRate / 100);
                }
                this.$set(this.form.details[index], 'unitMoney', this.$syInput(3, result));
            }
        },
        //input icon
        handleClickDefineInputIcon(scope, item) {
            this.chooseIndex = scope.rowIndex;
            this.openDialog = true;
        },
        //提交
        handleSubmit(flag) {
            this.$refs['form'].validate(async valid => {
                if (valid) {
                    this.$refs.myTable.handleValidateTable(flag);
                } 
            })
        },
        //表格数据验证回调
        handleTableCallback(data, flag) {
            this.submitForm(flag);
        },
        submitForm(flag) {
            console.log(this.form)
            //页面加载动画
            this.loading = true;
            const now = dayjs();
            let curTime = `${now.format('HH')}:${now.format('mm')}:${now.format('ss')}`;
            let obj = {
                "billDate": this.form.billDate + ' ' + curTime,
                "billDetailItems": this.form.details,
                "billLineCount": this.form.details.length,
                "billMoney": this.form.details.reduce((sum, obj) => sum + Number(obj.unitMoney), 0),
                "billNotaxMoney": this.form.details.reduce((sum, obj) => sum + Number(obj.unitMoney), 0),
                "billQty": this.form.details.reduce((sum, obj) => sum + Number(obj.unitQty), 0),
                "billRemark": this.form.billRemark,
                "billTaxMoney": this.form.details.reduce((sum, obj) => sum + Number(obj.unitMoney), 0),
                "payModeId": this.form.payModeId,
                "payMoney": this.form.payMoney,
                "receiveEmployeeId": this.form.receiveEmployeeId,
                "billId": this.form.billId,
                "receiveDeptId":this.form.receiveDeptId
            };
            let reqName = couponReceiveSave;
            if (this.form.billId) {
                reqName = couponReceiveUpdate;
            }
            reqName(obj).then(res => {
                this.loading = false;
                if (res.code == 200) {
                    if (!this.form.billId) {
                        this.$message.success("新增成功");
                    } else {
                        this.$message.success("修改成功");
                    }
                    this.form.billStatus = res.data.billStatus;
                    this.form.billId = res.data.billId;
                    if (flag) {
                        this.handleAdd();
                    }
                }
            }, err => {
                this.loading = false;
            })
        },
    },
};
</script>
<style lang="scss" scoped>
.instore-detail {
  background-color: #f3f3f3;

  //::v-deep .el-table--border {
  //    border: 1px solid #fff;
  //}

  .card {
    min-height: 90vh;

    //顶部区域
    .bill-nav {
      width: 100%;

      .info {
        width: 80%;
        display: flex;
        flex-wrap: wrap;

        .searchItem {
          display: flex;
          align-items: center;
          text-align: left;

          &.Address {
            width: 50%;

            .itemAddress {
              width: 100%;
            }
          }

          .el-select,
          .treeselect,
          .el-input,
          ::v-deep.el-date-editor {
            width: 160px;
          }

          .label-name {
            min-width: 4vw;
            margin-right: 0.52vw;
            text-align: right;
            text-align-last: justify;
          }
        }
      }
    }

    //供应商下拉框
    ::v-deep.el-select-dropdown__list {
      min-width: 400px !important;
    }

    //未审核图标
    .nav-noAudit-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/noAudit.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    //已审核图标
    .nav-audit-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/audit.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    //已删除图标
    .nav-delete-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/delete.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    //已关闭图标
    .nav-stop-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/stop.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    // 按钮区域
    .instore-btn {
      display: flex;
      justify-content: space-between;

      .noAuditBtn {
        position: absolute;
        z-index: 2022;
        bottom: -30px;
        transform: translateX(70%);
        display: none;

        &.show {
          display: block;
        }
      }
    }

    // 表格区域
    .instore-table {
      ::v-deep.el-form-item {
        margin-bottom: 0;
      }

      ::v-deep.el-form-item__content {
        margin-left: 0 !important;
      }

      .table-icon {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          height: 64px;
          width: 165px;
          background: url(~@/assets/images/audit.png) no-repeat;
          background-size: 164px 63px;
          top: calc(48% - 32px);
          left: calc(50% - 80px);
        }
      }
    }

    .instore-footer {
      display: flex;
      width: 100%;
      font-size: 14px;
      color: #606266;
      font-weight: 700;
      margin-top: 15px;
      padding-bottom: 49px;

      .left-accessory {
        display: flex;
        width: 50%;

        .btn {
          margin: 0 5px;
        }
      }

      .right-remark {
        width: 50%;
        display: flex;

        span {
          width: 10%;
        }

        .input {
          width: 90%;
        }
      }
    }
  }

  //底部区域
  .footer {
    height: 50px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-top: 1px solid #d6d8da;
    display: flex;

    .left {
      flex: 5;
    }

    .right {
      flex: 2;
      display: flex;

      .item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;

        .label {
          font-size: 14px;
        }
      }
    }
  }
}

.ken-detail-form {
  padding: 40px 10px 0 10px;
}

// ::v-deep .el-form-item {
//   margin: 0;
//   padding: 0;
// }
//输入框原生属性
/**
   * 解决el-input设置类型为number时，中文输入法光标上移问题
   **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
   * 解决el-input设置类型为number时，去掉输入框后面上下箭头
   **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::v-deep.el-table {
  ::v-deep.el-table__body-wrapper::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::v-deep.el-table__body-wrapper::-webkit-scrollbar-thnmb {
    background-color: #ccc !important;
    border-radius: 5px;
  }

  ::v-deep.el-table-fixed .el-table__body {
    padding-bottom: 12px;
  }
}
</style>
