<template>
  <div class="wrap" v-loading="loading" id="wrap">
    <el-form ref="form" :model="form" label-width="100px">
      <!-- 顶部按钮 -->
      <cardTitleCom cardTitle="基本信息" id="basicInfo">
        <template slot="cardContent">
          <div class="tableContentTop x-x">
            <el-form-item label="优惠券名称" prop="couponCaseName">
              <el-input
                v-model="form.couponCaseName"
                class="inputWidthM"
                size="mini"
                maxlength="20"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="编号规则" prop="couponNoRule">
              <el-input
                v-model="form.couponNoRule"
                class="inputWidthM"
                size="mini"
                maxlength="40"
                readonly
              >
                <i
                  class="el-icon-more el-input__icon"
                  slot="suffix"
                  @click="handleRuleClick(form.couponNoRule)"
                >
                </i>
              </el-input>
            </el-form-item>
            <el-form-item label="总张数" prop="couponCount">
              <el-input
                v-model="form.couponCount"
                class="inputWidthM"
                size="mini"
                maxlength="8"
                disabled
              ></el-input>
            </el-form-item>
          </div>
          <div class="tableContentTop x-x">
            <el-form-item label="销售张数" prop="sellCount">
              <el-input
                v-model="form.sellCount"
                class="inputWidthM"
                size="mini"
                maxlength="8"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="已领张数" prop="drawCount">
              <el-input
                v-model="form.drawCount"
                class="inputWidthM"
                size="mini"
                maxlength="8"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="作废张数" prop="scrapCount">
              <el-input
                v-model="form.scrapCount"
                class="inputWidthM"
                size="mini"
                maxlength="8"
                disabled
              ></el-input>
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom>
        <template slot="leftCardTitle">
          <div>
            <el-button type="primary" size="mini" @click="handleTableDetailClick"
              >添加优惠券</el-button
            >
            <el-button type="primary" size="mini" @click="handleLookDetail"
              >查看生成优惠券</el-button
            >
            <el-button @click="$router.push('/marketing/coupon/Case')" size="mini"
              >退出</el-button
            >
          </div>
        </template>
        <template slot="cardContent">
          <div class="tableContent marL15">
            <el-form-item>
              <el-table
                class="marT10"
                :data="form.detailData"
                :height="tableHeight"
                border
                @row-click="handleRowClick"
              >
                <el-table-column width="55" align="center">
                  <template v-slot="scope">
                    <el-radio v-model="templateItem" :label="scope.row">{{
                      ""
                    }}</el-radio>
                  </template>
                </el-table-column>
                <el-table-column label="开始流水号" align="center" prop="begGlideNo">
                </el-table-column>
                <el-table-column label="结束流水号" align="center" prop="endGlideNo">
                </el-table-column>
                <el-table-column label="张数" align="center" prop="addCount">
                </el-table-column>
                <el-table-column label="定义人员" align="center" prop="createBy">
                </el-table-column>
                <el-table-column label="定义日期" align="center" prop="createTime">
                </el-table-column>
              </el-table>
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 优惠券规则 -->
    <c-dialog title="编号规则" :width="450" :showDialog.sync="dialogCouponRule">
      <template #content>
        <el-form
          :model="couponRuleForm"
          ref="couponRule"
          label-width="70px"
          v-loading="loadingCouponRule"
        >
          <el-form-item>
            <el-checkbox v-model="couponRuleForm.case" disabled>方案编号 </el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="couponRuleForm.flow" disabled>流水码 </el-checkbox>
          </el-form-item>
          <el-form-item>
            <div class="ruleDiv">
              <el-checkbox v-model="couponRuleForm.year">年份 </el-checkbox>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="ruleDiv">
              <el-checkbox v-model="couponRuleForm.check">校验码 </el-checkbox>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="ruleDiv">
              <el-checkbox v-model="couponRuleForm.customize">自定义码 </el-checkbox>
              <el-input
                :disabled="!couponRuleForm.customize"
                maxlength="4"
                oninput="value=value.replace(/^0|[^0-9]/g,'')"
                style="width: 170px"
                placeholder="自定义码"
                v-model="couponRuleForm.customizeNumber"
                size="mini"
              />
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button type="primary" @click="handleConfirmCouponRule">确定</el-button>
        <el-button @click="dialogCouponRule = false">取消</el-button>
      </template>
    </c-dialog>
    <!-- 添加优惠券 -->
    <c-dialog title="添加优惠券" :width="700" :showDialog.sync="dialogAddCoupon">
      <template #content>
        <el-form
          :model="addCouponForm"
          ref="addCouponForm"
          label-width="120px"
          v-loading="loadingAddCouponForm"
        >
          <div class="x-x">
            <el-form-item label="单据编号">
              <el-input
                disabled
                style="width: 170px"
                v-model="addCouponForm.couponCaseNo"
                size="mini"
              />
            </el-form-item>
            <el-form-item label="单据日期">
              <el-input
                disabled
                style="width: 170px"
                size="mini"
                v-model="addCouponForm.createTime"
              />
            </el-form-item>
          </div>
          <div class="x-x">
            <el-form-item label="优惠券类型">
              <el-input
                disabled
                style="width: 170px"
                v-model="addCouponForm.couponCaseTypeName"
                size="mini"
              />
            </el-form-item>
            <el-form-item label="有效日期">
              <el-input
                disabled
                style="width: 170px"
                v-model="addCouponForm.validEndTime"
                size="mini"
              />
            </el-form-item>
          </div>
          <div class="x-x">
            <el-form-item label="开始流水号">
              <el-input
                disabled
                style="width: 170px"
                v-model="addCouponForm.begGlideNo"
                size="mini"
              />
            </el-form-item>
            <el-form-item label="结束流水号">
              <el-input
                disabled
                style="width: 170px"
                v-model="addCouponForm.endGlideNo"
                size="mini"
              />
            </el-form-item>
          </div>
          <div class="x-x">
            <el-form-item label="面值">
              <el-input
                disabled
                style="width: 170px"
                size="mini"
                v-model="addCouponForm.parValue"
              />
            </el-form-item>
            <el-form-item label="入库张数">
              <el-input
                style="width: 170px"
                placeholder="入库张数"
                v-model="addCouponForm.addCount"
                oninput="value=value.replace(/^0|[^0-9]/g,'')"
                size="mini"
                @input="addCountInput"
              />
            </el-form-item>
          </div>
          <div class="x-x">
            <el-form-item label="定义人员">
              <el-input
                disabled
                style="width: 170px"
                size="mini"
                v-model="addCouponForm.createBy"
              />
            </el-form-item>
          </div>
        </el-form>
      </template>
      <template #buttonList>
        <!-- <el-button type="primary" @click="handlePrint" style="float:left">打印</el-button> -->
        <el-button type="primary" @click="handleConfirmAddCoupon">确定</el-button>
        <el-button @click="dialogAddCoupon = false">取消</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import {
  setCouponNoRule,
  getAddMarketingCouponDetail, //新增
  addMarketingCouponLog,
} from "@/api/marketing/coupon/case";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
export default {
  name: "GenerateCouppon",
  components: {
    cardTitleCom,
    CDialog,
  },
  data() {
    return {
      //禁用
      disabled: false,

      //表格遮罩
      loadingTable: false,
      //整体遮罩
      loading: false,
      //旧的表单
      oldForm: {},
      //表单
      form: {
        detailData: [{ commissionCalcType: 1 }], //销售提成表格
      },
      //旧的表单
      oldForm: {},
      dialogCouponRule: false,
      couponRuleForm: {
        case: true,
        flow: true,
        year: false,
        check: false,
        customize: false,
        customizeNumber: "",
      },
      loadingCouponRule: false,
      dialogAddCoupon: false,
      addCouponForm: {},
      loadingAddCouponForm: false,
      couponCaseId: "",
      templateItem: {},
      tableHeight: 600,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      // 重置数据
      vm.reset();
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === "Update") {
        if (vm.$route.query.couponCaseId) {
          vm.couponCaseId = vm.$route.query.couponCaseId;
          //获取方案详情
          const { data } = await getAddMarketingCouponDetail(
            vm.$route.query.couponCaseId
          );

          // //方案赋值
          vm.form = data;
          if (data.marketingCouponCaseAddLogs?.length > 0) {
            vm.form.detailData = data.marketingCouponCaseAddLogs;
          }
          vm.addCouponForm = data;
        }
      }
      vm.loading = false;
    });
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.form.billId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.form));
    }
    next();
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      setTimeout(() => {
        const wrapH = document.getElementById("wrap")?.clientHeight || 0;
        const basicInfoH = document.getElementById("basicInfo")?.clientHeight || 0;
        this.tableHeight = wrapH - basicInfoH - 90;
        console.log("页面高度", wrapH);
      }, 10);
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.templateItem = row;
    },
    handleRuleClick(text) {
      if (this.form.detailData?.length > 0) {
        this.$message.warning("已存在优惠券不允许修改规则！");
        return;
      }
      this.couponRuleForm = {
        case: true,
        flow: true,
        year: false,
        check: false,
        customize: false,
        customizeNumber: "",
      };
      if (text) {
        if (text.indexOf("年份") > -1) {
          this.couponRuleForm.year = true;
        }
        if (text.indexOf("校验码") > -1) {
          this.couponRuleForm.check = true;
        }
        const numbers = text.match(/\d+/g);
        if (numbers.length > 0) {
          this.couponRuleForm.customize = true;
          this.couponRuleForm.customizeNumber = numbers[0];
        }
      }
      this.dialogCouponRule = true;
    },
    async handleConfirmCouponRule() {
      let couponNoRule = "[方案编号][流水码]";
      if (this.couponRuleForm.year) {
        couponNoRule += "[年份]";
      }
      if (this.couponRuleForm.check) {
        couponNoRule += "[校验码]";
      }
      if (this.couponRuleForm.customize) {
        couponNoRule += "[" + this.couponRuleForm.customizeNumber + "]";
      }
      try {
        await setCouponNoRule({
          couponCaseId: this.couponCaseId,
          couponNoRule,
        });
        this.$set(this.form, "couponNoRule", couponNoRule);
        this.dialogCouponRule = false;
      } catch (e) {
        console.log(e);
      }
    },
    async handleTableDetailClick() {
      this.$set(this.addCouponForm, "addCount", "");
      this.$set(this.addCouponForm, "couponGlideNoEnd", "");
      if (!this.form.couponNoRule) return this.$message.warning("请先选择编号规则");
      try {
        //获取方案详情
        const { data } = await getAddMarketingCouponDetail(this.couponCaseId);
        this.addCouponForm = data;
        this.dialogAddCoupon = true;
      } catch (err) {
        this.$message.error("获取优惠券详情失败");
      }
    },
    handleLookDetail() {
      if (!this.templateItem.couponCaseId) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$router.push({
        path: "/marketing/coupon/drawRecord",
        query: {
          couponCaseId: this.templateItem.couponCaseId, //优惠券id
          addLogId: this.templateItem.addLogId,
          type: "Update",
        },
      });
    },
    addWithLeadingZeros(num1, num2) {
      // 将数字转换为字符串
      num1 = num1.toString();
      num2 = num2.toString();
      // 执行加法
      let sum = (parseInt(num1) + parseInt(num2)).toString();
      // 返回保留前导零的结果
      return sum.padStart(Math.max(num1.length, num2.length), "0");
    },
    async handleConfirmAddCoupon() {
      if (!this.addCouponForm.addCount) {
        this.$message.warning("请输入入库张数!");
        return;
      }
      if (this.addCouponForm.addCount < 1) {
        this.$message.warning("入库张数不能为0!");
        return;
      }
      if (this.addCouponForm.couponGlideNo) {
        this.$set(
          this.addCouponForm,
          "couponGlideNoEnd",
          this.addWithLeadingZeros(
            this.addCouponForm.couponGlideNo,
            Number(this.addCouponForm.addCount) - 1
          )
        );
      }
      try {
        let res = await addMarketingCouponLog({
          addCount: this.addCouponForm.addCount,
          begGlideNo: this.addCouponForm.couponGlideNo,
          couponCaseId: this.couponCaseId,
          endGlideNo: this.addCouponForm.endGlideNo,
          validBegTime: this.addCouponForm.validBegTime,
          validEndTime: this.addCouponForm.validEndTime,
        });
        if (res.code == 200) {
          this.$message.success("操作成功");
          const response = await getAddMarketingCouponDetail(this.couponCaseId);
          console.log(response);
          this.dialogAddCoupon = false;
          this.form.detailData = response.data.marketingCouponCaseAddLogs || [];
        }
      } catch (e) {}
    },
    // 输入入库张数
    addCountInput(e) {
      if (this.addCouponForm.begGlideNo && e) {
        let endGlideNo = Number(this.addCouponForm.begGlideNo) + Number(e) - 1;
        this.addCouponForm.endGlideNo =
          String(endGlideNo).length < 6
            ? endGlideNo.toString().padStart(6, "0")
            : endGlideNo;
      }
    },
    // 表单重置
    reset() {
      this.form = {
        detailData: [{ commissionCalcType: 1 }], //销售提成表格
      };
      this.couponRuleForm = {
        case: true,
        flow: true,
        year: false,
        check: false,
        customize: false,
        customizeNumber: "",
      };
      this.addCouponForm = {};
      this.templateItem = {};
      this.resetForm("form");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  //基本信息内容
  .tableContentTop {
    padding: 6px 10px;
  }
  //表格
  .tableContent {
    padding: 6px 10px;
    ::v-deep .el-form-item__content {
      margin-left: 0 !important;
    }
    ::v-deep .el-form-item__error {
      position: static;
    }
  }
}
//表单原生样式
::v-deep .el-form-item {
  margin: 0;
  padding: 0;
}
.ruleDiv {
  width: 250px;
  display: flex;
  justify-content: space-between;
}
</style>
